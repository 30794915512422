import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import * as Yup from "yup";
import { saveBranch } from "../../services";

const AddBranch = () => {
  const createBranch = async (values) => {
    try {
      await saveBranch(values)
      window.location.href = "/branches"
    } catch (e) {
      console.log(e)
    }
  }
  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">New Branch</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/branches">Branches</a>
                  </li>
                  <li className="breadcrumb-item active">New branch</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Branch Info</h3>
                  </div>

                  <Formik
                    initialValues={{ name_en: "", name_ar: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                      createBranch(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      name_en: Yup.string().required(),
                      name_ar: Yup.string().required(),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <label>Name (EN)</label>
                              <input
                                type="text"
                                name="name_en"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name_en}
                                className="form-control"
                                placeholder="Branch name (English)"
                              />
                              {errors.name_en && touched.name_en && (
                                <span className="text-danger text-sm">
                                  {errors.name_en}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <label>Name (AR)</label>
                              <input
                                type="text"
                                name="name_ar"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name_ar}
                                className="form-control"
                                placeholder="Branch name (Arabic)"
                              />
                              {errors.name_en && touched.name_en && (
                                <span className="text-danger text-sm">
                                  {errors.name_en}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="card-footer">
                            <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                              Add Branch
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBranch;
