import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from '@mui/material/Divider';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { fetchVariations, saveVariation, fetchProducts, updateVariationStock, fetchVariationOrders, updateProduct } from "../../services";
import ReactToPrint from "react-to-print";
var Barcode = require("react-barcode");
var JsBarcode = require("jsbarcode");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Variations = () => {
  const [activeVariation, setActiveVariation] = useState(null),
    [ordersModalOpen, setOrdersModelOpen] = useState(false),
    [variationOrders, setVariationOrders] = useState([]),
    [totalPairs, setTotalPairs] = useState(0),
    [open, setOpen] = useState(false),
    { product_id } = useParams(),
    [bulkPrice, setBulkPrice] = useState(""),
    [barcodeInfoVisible, setBarcodeInfoVisible] = useState(false),
    [activeBarcodeImage, setActiveBarcodeImage] = useState(null)

  const loadOrders = async (variationId) => {
    try {
      let orders = await fetchVariationOrders(variationId)
      console.log(orders)
      setOrdersModelOpen(true)
      setVariationOrders(orders.orders)
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message)
      console.log(e)
    }
  }

  const columns: GridColDef[] = [
    {
      field: "color",
      headerName: "Color (EN)",
      // width: 150,
      flex: 0.5,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "color_ar",
      headerName: "Color (AR)",
      // width: 150,
      flex: 0.5,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "barcode",
      headerName: "Barcode",
      flex: 0.6,
      // renderCell: (params) => {
      //   var canvas = document.createElement("canvas");
      //           JsBarcode(canvas, params.row.barcode, { format: "EAN13" });
      //           return <img src={canvas.toDataURL("image/png")} height="50" />
      // }
    },
    {
      field: "size",
      headerName: "Size",
      // width: 100,
      flex: 0.3,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "qty",
      headerName: "Stock",
      // width: 100,
      flex: 0.5,
      headerAlign: "right",
      align: "right",
    },
    {
      field: "cost",
      headerName: "Unit Cost",
      type: "number",
      // width: 50,
      flex: 0.8,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return <span>EGP {params.row.cost.toFixed(2)}</span>
      },
    },
    {
      field: "old_price",
      headerName: "Old Price",
      sortable: true,
      // width: 160,
      flex: 0.5,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return <span>EGP {params.row.old_price.toFixed(2)}</span>
      },
    },
    {
      field: "new_price",
      headerName: "New Price",
      sortable: true,
      // width: 160,
      flex: 0.5,
      headerAlign: "right",
      align: "right",
      renderCell: (params) => {
        return <span>EGP {params.row.new_price.toFixed(2)}</span>
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "left",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row justify-content-space-between align-items-center">
            <a
              className="btn btn-sm btn-default mr-2"
              href={`/variations/edit/${params.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </a>

            <button
              className="btn btn-sm btn-success mr-2"
              data-toggle="tooltip"
              data-placement="top"
              title="Orders"
              disabled={Number(params.row.has_orders) <= 0}
              onClick={() => {
                loadOrders(params.row.id)
              }}
            >
              <i className="fa fa-basket-shopping" />
            </button>

            <button
              className="btn btn-sm btn-primary mr-2"
              // href={`/variations/stock/${params.id}`}
              data-toggle="tooltip"
              onClick={() => {
                handleClickOpen()
                setActiveVariation(params.row)
              }}
              data-placement="top"
              title="Branch Stock"
            >
              <i className="fa fa-list" />
            </button>

            <button
              className="btn btn-sm btn-warning mr-2"
              data-toggle="tooltip"
              data-placement="top"
              title="Print barcode"
              onClick={() => {
                setBarcodeInfoVisible(true)
                var canvas = document.createElement("canvas");
                JsBarcode(canvas, params.row.barcode, { format: "EAN13" });
                setActiveBarcodeImage(canvas.toDataURL("image/png"))
                setActiveVariation(params.row)
              }}
            >
              <i className="fa fa-barcode" />
            </button>

            <button
              className={`btn btn-sm btn-${parseInt(params.row.active) ? "danger" : "success"
                }`}
              data-toggle="tooltip"
              data-placement="top"
              title={`${parseInt(params.row.active) ? `Deactivate` : `Activate`
                }`}
            >
              <i
                className={`fa fa-${parseInt(params.row.active) ? "times" : "check"
                  }`}
              />
            </button>
          </div>
        );
      },
    },
  ];

  const { data: variations, isLoading } = useQuery(
    "variations",
    () => fetchVariations(product_id),
    {
      select: (data) => {
        return data.variations;
      },
      onSuccess: (data) => setTotalPairs(data.reduce((acc, obj) => acc + obj.qty, 0)),
      refetchOnWindowFocus: false,
    }
  );

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const { data: product, isLoading: isProductLoading } = useQuery(
    "product",
    () => fetchProducts({ id: product_id }),
    {
      select: (data) => {
        return data.products[0];
      },
      refetchOnWindowFocus: false,
    }
  );

  const submitVariation = async (variationsArray) => {
    try {
      await saveVariation(variationsArray, product_id);
      window.location.reload()
    } catch (e) {
      if (e.data.message) alert(e.data.message);
      console.log(e);
    }
  }

  const bulkPriceUpdate = async () => {
    try {
      await updateProduct({ new_price: bulkPrice }, product.id)
      alert("Prices has been successfully update")
      window.location.reload()
    } catch (e) {
      if (e.data && e.data.message) alert(e.data.message)
    }
  }

  const saveStock = async (values) => {
    try {
      updateVariationStock(values, activeVariation.id)
      handleClose()
    } catch (e) {
      if (e.data.message) alert(e.data.message);
      console.log(e);
    }
  }

  if (isLoading || isProductLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
      <BootstrapDialog
          onClose={() => setBarcodeInfoVisible(false)}
          aria-labelledby="customized-dialog-title"
          fullWidth={true}
          maxWidth={"sm"}
          open={barcodeInfoVisible}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {`${activeVariation?.sku} ${activeVariation?.color}-${activeVariation?.size} barcode info`}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setBarcodeInfoVisible(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers style={{textAlign: "center"}}>
            <h5>{`ML` + activeVariation?.sku} {activeVariation?.color} {activeVariation?.size}</h5>
            {/* <img src={activeBarcodeImage} alt="barcode" style={{ height: "100px" }} /> */}
            <h5>{activeVariation?.barcode}</h5>
            <h5>EGP {activeVariation?.new_price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h5>
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={() => setOrdersModelOpen(false)}
          aria-labelledby="customized-dialog-title"
          // fullScreen={true}
          fullWidth={true}
          maxWidth={"lg"}
          open={ordersModalOpen}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {variationOrders.length && `${variationOrders[0].sku} ${variationOrders[0].color}-${variationOrders[0].size} orders`}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setOrdersModelOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent dividers>
            <table style={{ width: "100%" }}>
              <thead>
                <tr style={{ backgroundColor: "#f5f5f5" }}>
                  <th>Invoice No.</th>
                  <th>Customer</th>
                  <th>Purchase date</th>
                  <th>Branch</th>
                  <th>Status</th>
                  <th>Original Price</th>
                  <th>Discounted Price</th>
                </tr>
              </thead>

              <tbody>
                {
                  variationOrders && variationOrders.map((item, itemIndex) => {
                    return (<tr style={{ height: 50 }} key={itemIndex}>
                      <td>{item.invoice_no}</td>
                      <td>{item.customer_name}</td>
                      <td>{item.purchase_date}</td>
                      <td>{item.branch_name_en}</td>
                      <td>{item.is_restocked ? <div className="badge badge-danger">Re-Stocked</div> : (item.is_refunded ? <div className="badge badge-danger">Refunded</div> : <span>-</span>)}</td>
                      <td>EGP {item.new_price}</td>
                      <td>EGP {item.price}</td>
                    </tr>)
                  })
                }
              </tbody>

              <tfoot style={{ backgroundColor: "#f5f5f5" }}>
                <tr>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  <th>&nbsp;</th>
                  {/* <th style={{ textAlign: "left" }}>Total: EGP {orderItems && orderItems.total}</th> */}
                </tr>
              </tfoot>
            </table>
          </DialogContent>
        </BootstrapDialog>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            {activeVariation ? `${activeVariation.sku} ${activeVariation.color}-${activeVariation.size} stock` : ``}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <Formik
            // initialValues={{ name_en: "", name_ar: "", dep_ids: [] }}
            initialValues={activeVariation?.branches}
            onSubmit={(values, { setSubmitting }) => {
              saveStock(values);
              setSubmitting(false);
            }}
            validationSchema={Yup.object().shape({
              qty: Yup.number().required(),
            })}
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                isSubmitting,
                setFieldValue,
                handleChange,
                handleBlur,
                handleSubmit,
              } = props;

              return (
                <form onSubmit={handleSubmit}>
                  <DialogContent dividers>
                    {/* <Typography gutterBottom>
                              Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
                              dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
                              ac consectetur ac, vestibulum at eros.
                            </Typography> */}
                    <div className="container">
                      <div className="card-body">

                        {
                          activeVariation.branches.map((b, index) => (
                            <div className="form-group" key={index}>
                              <div className="row">
                                <div className="col-lg-8">
                                  <label>{b.branch_name}</label>
                                </div>

                                <div className="col-lg-2">
                                  <input
                                    type="number"
                                    name={`${index}.qty`}
                                    onChange={handleChange}
                                    placeholder="Branch Stock"
                                    defaultValue={b.qty}
                                    className="form-control"
                                  />
                                </div>
                              </div>
                            </div>
                          ))
                        }

                        <Divider sx={{ mb: 2 }} />

                        <div className="form-group">
                          <div className="row">
                            <div className="col-lg-8">Total Stock</div>
                            <div className="col-lg-3">{activeVariation.qty}</div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </DialogContent>

                  <DialogActions>
                    <Button
                      autoFocus
                      // onClick={() => saveStock()}
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save changes
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          </Formik>

        </BootstrapDialog>

        <div className="card bg-light">
          <div className="card-header">
            {" "}
            <i>{product?.department_name_en} {product?.category_name_en} {`->`} {product?.sku}</i> New Variation
          </div>
          <div className="card-body">
            {/* <a href={`/new-variation/${product_id}`} className="btn btn-primary btn-sm">
                  New variation
                </a> */}

            <Formik
              // initialValues={{
              //   color: "",
              //   size: "",
              //   qty: "",
              //   old_price: "",
              //   new_price: "",
              // }}
              initialValues={{
                newVariations: [
                  {
                    color: "",
                    color_ar: "",
                    size: "",
                    old_price: "",
                    new_price: "",
                    qty: "",
                  },
                ],
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  submitVariation(values);
                  // console.log(values)
                  setSubmitting(false);
                }, 500);
              }}
            // validationSchema={Yup.object().shape({
            //   color: Yup.string().required(),
            //   size: Yup.number().required(),
            //   qty: Yup.number().required(),
            //   old_price: Yup.number().required(),
            //   new_price: Yup.number().required()
            // })}
            >
              {(props) => {
                const {
                  values,
                  touched,
                  errors,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                } = props;

                return (
                  <Form>
                    <FieldArray
                      name="newVariations"
                      render={(arrayHelpers) => (
                        <div>
                          {values.newVariations &&
                            values.newVariations.length ? (
                            values.newVariations.map((v, index) => (
                              <div className="form-group" key={index}>
                                <div className="row" key={index}>
                                  <div className="col-lg-2">
                                    <label>Color</label>

                                    <Field
                                      type="text"
                                      name={`newVariations[${index}].color`}
                                      className="form-control"
                                      placeholder="Color (EN)"
                                      onChange={handleChange}
                                      value={values.newVariations[index].color}
                                    />
                                  </div>

                                  <div className="col-lg-2">
                                    <label>Color (AR)</label>

                                    <Field
                                      type="text"
                                      name={`newVariations[${index}].color_ar`}
                                      className="form-control"
                                      placeholder="Color (AR)"
                                      onChange={handleChange}
                                      value={
                                        values.newVariations[index].color_ar
                                      }
                                    />
                                  </div>

                                  <div className="col-lg-1">
                                    <label>Size</label>

                                    <Field
                                      type="number"
                                      name={`newVariations[${index}].size`}
                                      className="form-control"
                                      placeholder="Size"
                                      onChange={handleChange}
                                      value={
                                        values.newVariations[index].size
                                      }
                                    />
                                  </div>

                                  <div className="col-lg-2">
                                    <label>Old Price</label>

                                    <Field
                                      type="number"
                                      name={`newVariations[${index}].old_price`}
                                      className="form-control"
                                      placeholder="Old Price"
                                      onChange={handleChange}
                                      value={
                                        values.newVariations[index].old_price
                                      }
                                    />
                                  </div>

                                  <div className="col-lg-2">
                                    <label>New Price</label>

                                    <Field
                                      type="number"
                                      name={`newVariations[${index}].new_price`}
                                      className="form-control"
                                      placeholder="New Price"
                                      onChange={handleChange}
                                      value={
                                        values.newVariations[index].new_price
                                      }
                                    />
                                  </div>

                                  <div className="col-lg-1">
                                    <label>Qty.</label>

                                    <Field
                                      type="number"
                                      name={`newVariations[${index}].qty`}
                                      placeholder="Qty."
                                      className="form-control"
                                      onChange={handleChange}
                                      value={
                                        values.newVariations[index].qty
                                      }
                                    />
                                  </div>

                                  <div className="col-lg-1">
                                    <label style={{ display: "block" }}>
                                      &nbsp;
                                    </label>

                                    {index === 0 ? (
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-default"
                                        onClick={() => arrayHelpers.push("")}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-danger"
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <i className="fa fa-minus" />
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <button
                              type="button"
                              onClick={() => arrayHelpers.push("")}
                            >
                              <i className="fa fa-plus" />
                            </button>
                          )}

                          <button
                            type="submit"
                            disabled={isSubmitting}
                            className="btn btn-primary"
                          >
                            Save Changes
                          </button>

                          <Divider sx={{ mt: 3, mb: 3 }} />

                          <h6 className="text-uppercase mb-3 text-muted">Update all prices for {product.sku}</h6>
                          <div className="row">
                            <div className="col-md-2">
                              <input type="number" className="form-control" placeholder="New Price" onChange={({ target }) => setBulkPrice(target.value)} />
                            </div>

                            <div className="col-md-2">
                              <button
                                className="btn btn-block bg-gradient-warning"
                                disabled={!bulkPrice.length}
                                onClick={() => bulkPriceUpdate()}
                              >Bulk update</button>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>

        <div className="content-header">
          {/* <div className="container"> */}
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">
                Variations{" "}
                <span className="text-muted text-sm">
                  ({variations.length} results - {totalPairs} pairs)
                </span>
              </h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                {/* <li className="breadcrumb-item active">Dashboard</li> */}
              </ol>
            </div>
          </div>
          {/* </div> */}
        </div>

        <div className="content">

          <Box sx={{ height: "60vh", width: "100%" }}>
            <DataGrid
              rows={variations}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
              }}
              pageSizeOptions={[50]}
              disableRowSelectionOnClick
            />
          </Box>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Variations;
