import React, { Component, useReact, useEffect } from "react";

const LockScreen = () => {
    useEffect(() => {
        document.body.className = 'hold-transition lockscreen';
        return () => { document.body.className = 'hold-transition sidebar-mini'; }
    }, [])

  return (
    <div className="lockscreen-wrapper">
      <div className="lockscreen-logo">
        <a href="../../index2.html">
          {/* <b>Admin</b>LTE */}
          <img src={`${process.env.PUBLIC_URL}/images/logo-dark.png`} height="100" />
        </a>
      </div>
      <div className="lockscreen-name text-center">Welcome back, John Doe</div>
      <div className="lockscreen-item">
        {/* <div className="lockscreen-image">
          <img src="../../dist/img/user1-128x128.jpg" alt="User Image" />
        </div> */}
        <form className="lockscreen-credentials">
          <div className="input-group">
            <input
              type="password"
              className="form-control"
              placeholder="password"
            />
            <div className="input-group-append">
              <button type="button" className="btn">
                <i className="fas fa-arrow-right text-muted" />
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="help-block text-center">
        Enter your password to retrieve your session
      </div>
      <div className="text-center">
        <a href="login.html">Or sign in as a different user</a>
      </div>
    </div>
  );
};

export default LockScreen;
