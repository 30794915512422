import React, { useEffect } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import { fetchDepartments, saveSupplier } from "../../services";
import * as Yup from "yup";
import { useQuery } from "react-query";
import Select from "react-select";

const AddSupplier = () => {
  const { data: departments, isLoading } = useQuery(
    "departments",
    fetchDepartments,
    {
      select: (data) =>
        data.departments.map((s) => {
          return { label: s.name_en, value: s.id };
        }),
      refetchOnWindowFocus: false,
    }
  );

  const createSupplier = async (data) => {
    try {
      await saveSupplier(data);
      window.location.href = "/suppliers"
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">New Supplier</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/suppliers">Suppliers</a>
                  </li>
                  <li className="breadcrumb-item active">New supplier</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Supplier Info</h3>
                  </div>

                  <Formik
                    // initialValues={{ name_en: "", name_ar: "", dep_ids: [] }}
                    initialValues={{ name_en: "", name_ar: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                      createSupplier(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      name_en: Yup.string().required(),
                      name_ar: Yup.string().required(),
                      /*dep_ids: Yup.string().required(
                        "Please select at least one department"
                      ),*/
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <label>Name (EN)</label>
                              <input
                                type="text"
                                className="form-control"
                                name="name_en"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name_en}
                                placeholder="Supplier name (English)"
                              />
                              {errors.name_en && touched.name_en && (
                                <span className="text-danger text-sm">
                                  {errors.name_en}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <label>Name (AR)</label>
                              <input
                                type="text"
                                name="name_ar"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.name_ar}
                                className="form-control"
                                placeholder="Supplier name (Arabic)"
                              />
                              {errors.name_ar && touched.name_ar && (
                                <span className="text-danger text-sm">
                                  {errors.name_ar}
                                </span>
                              )}
                            </div>
                            {/*<div className="form-group">
                              <label>Department</label>
                              {isLoading ? (
                                "Loading..."
                              ) : (
                                <>
                                  <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    name="dep_ids"
                                    defaultValue={departments.find((d) => {
                                      return d.value === props.values.id ? d.id : null;
                                    })}
                                    onChange={ (value) => {
                                      setFieldValue("dep_ids", value.map((sv) => {
                                        return sv.value
                                      }).join(","))
                                    }}
                                    onBlur={handleBlur}
                                    options={departments}
                                  />
                                  {errors.dep_ids && touched.dep_ids && (
                                    <span className="text-danger text-sm">
                                      {errors.dep_ids}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>

                            <div className="form-group">
                              <a className="text-primary" href="new-department">
                                <u>New department?</u>
                              </a>
                            </div>*/}
                          </div>
                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Add supplier
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSupplier;
