import React, { useState } from "react";
import { Box, Chip } from "@mui/material";
import { useQuery } from "react-query";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { fetchDiscountOptions } from "../../services";

const columns: GridColDef[] = [
  {
    field: "name_en",
    headerName: "Name (EN)",
    width: 150,
  },
  {
    field: "disc_val",
    headerName: "Value",
    width: 150,
    renderCell: (params) => {
      return (
        <span>
          {params.row.is_percent
            ? `${params.row.disc_val}%`
            : `EGP ${params.row.disc_val}`}
        </span>
      );
    },
  },
  {
    field: "is_bogo",
    headerName: "BOGO",
    flex: 0.5,
    renderCell: (params) => {
      return (
        <Chip
          size="small"
          color={params.row.is_bogo ? `success` : `default`}
          label={params.row.is_bogo ? `BOGO` : `-`}
        />
      );
    },
  },
  {
    field: "active",
    headerName: "Status",
    flex: 0.5,
    renderCell: (params) => {
      return (
        <Chip
          size="small"
          color={params.row.active ? `success` : `default`}
          label={params.row.active ? `Active` : `Disabled`}
        />
      );
    },
  },
  {
    field: "formatted_date_add",
    headerName: "Created On",
    flex: 0.5,
  },
  {
    field: "actions",
    headerName: "Actions",
    flex: 0.5,
    renderCell: (params) => {
      return (
        <div className="d-flex flex-row">
          <button
            className={`btn btn-sm btn-${
              parseInt(params.row.active) ? "danger" : "success"
            }`}
            data-toggle="tooltip"
            data-placement="top"
            title={`${parseInt(params.row.active) ? `Deactivate` : `Activate`}`}
          >
            <i
              className={`fa fa-${
                parseInt(params.row.active) ? "times" : "check"
              }`}
            />
          </button>
        </div>
      );
    },
  },
];

const Discounts = () => {
  const { data: discountOptions, isLoading } = useQuery(
    "discount_options",
    () => fetchDiscountOptions(),
    {
      select: (data) => data.discounts,
    }
  );

  if (isLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Discounts</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right"></ol>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="row mb-4">
            <div className="col-lg-3">
              <a href="/new-employee" className="btn btn-primary btn-sm">
                New discount
              </a>
            </div>
          </div>

          <Box sx={{ height: "75vh", width: "100%" }}>
            <DataGrid
              rows={discountOptions}
              columns={columns}
              getRowId={(row) => row?.id}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              pageSizeOptions={[15]}
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default Discounts;
