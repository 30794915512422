import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const fetchSuppliers = async (supplierId=null) => {
    return request({
        url: `${BaseUrl}v1/suppliers${supplierId ? `?id=${supplierId}` : ``}`
    }, true)
}

export const fetchDepartments = async (supplierId=null) => {
    return request({
        url: `${BaseUrl}v1/departments${supplierId ? `?supplier_id=${supplierId}` : ``}`
    }, true)
}

/*export const fetchCategories = async (depId) => {
    return request({
        url: `${BaseUrl}v1/categories/${depId}`
    }, true)
}*/

export const fetchCategories = async (supplierId) => {
    return request({
        url: `${BaseUrl}v1/categories${supplierId ? `?supplier_id=${supplierId}` : ``}`
    }, true)
}

export const saveSupplier = async (data) => {
    return request({
        url: `${BaseUrl}v1/supplier`,
        method: "POST",
        data
    }, true)
}

export const updateSupplier = async (data, supplierId) => {
    return request({
        url: `${BaseUrl}v1/supplier/${supplierId}`,
        method: "PUT",
        data
    }, true)
}

export const updateSupplierDepartments = async (data, supplierId) => {
    return request({
        url: `${BaseUrl}v1/supplier-departments/${supplierId}`,
        method: "PUT",
        data
    }, true)
}

export const saveDepartment = async (data) => {
    return request({
        url: `${BaseUrl}v1/department`,
        method: "POST",
        data
    }, true)
}

export const fetchSupplierDepartments = async (supplierId) => {
    return request({
        url: `${BaseUrl}v1/supplier-departments/${supplierId}`,
        method: "GET"
    }, true)
}