import Cookies from "js-cookie"

const getToken = () => {
    return Cookies.get("admintoken")
}

const setToken = (name, opts) => {
    Cookies.set(name, `${opts.value}`)
}

const signout = () => {
    Cookies.remove("admintoken")
    window.location.href = process.env.REACT_APP_URL
}

const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export { getToken, setToken, signout, capitalize }