import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const fetchProducts = async (filters) => {
    let queryStr = `?`
    Object.entries(filters).map(([key, value]) => {
        queryStr += `${key}=${value}&`
    })
    queryStr = queryStr.substring(0, queryStr.length - 1)
    console.log(queryStr)
    return request({
        url: `${BaseUrl}v1/products${queryStr}`
    }, true)
}

export const saveProduct = async (data) => {
    return request({
        url: `${BaseUrl}v1/product`,
        method: "POST",
        data
    }, true)
}

export const fetchVariations = async (productId=null, variationId=null) => {
    return request({
        url: `${BaseUrl}v1/variations${productId ? `/${productId}` : ``}${variationId ? `?variation_id=${variationId}` : ``}`
    }, true)
}

export const updateVariation = async (data, variationId) => {
    return request({
        url: `${BaseUrl}v1/update-variation/${variationId}`,
        method: "PUT",
        data
    }, true)
}

export const updateVariationStock = async (data, variationId) => {
    return request({
        url: `${BaseUrl}v1/update-stock/${variationId}`,
        method: "PUT",
        data
    }, true)
}

export const fetchVariationInfo = async (variationId) => {
    return request({
        url: `${BaseUrl}v1/variation-info/${variationId}`
    }, true)
}

export const saveVariation = async (data, productId) => {
    return request({
        url: `${BaseUrl}v1/variation/${productId}`,
        method: "POST",
        data
    }, true)
}

export const saveBranchStock = async (data, variationId) => {
    return request({
        url: `${BaseUrl}v1/set-branch-stock/${variationId}`,
        method: "POST",
        data
    }, true)
}

export const fetchImports = async (filters) => {
    let queryStr = `?`
    Object.entries(filters).map(([key, value]) => {
        queryStr += `${key}=${value}&`
    })
    queryStr = queryStr.substring(0, queryStr.length - 1)
    
    return request({
        url: `${BaseUrl}v1/imports${queryStr}`
    }, true)
}

export const fetchVariationOrders = async (variationId) => {
    return request({
        url: `${BaseUrl}v1/variation-orders/${variationId}`
    }, true)
}

export const importInventory = async (data) => {
    return request({
        url: `${BaseUrl}v1/import-inventory`,
        method: "POST",
        data
    }, true)
}

export const updateProduct = async (data, productId) => {
    return request({
        url: `${BaseUrl}v1/update-prices/${productId}`,
        method: "PUT",
        data
    }, true)
}

export const fetchDiscountOptions = async () => {
    return request({
        url: `${BaseUrl}v1/discounts`,
    }, true)
}