import react, { useState, useEffect, Component } from "react"
import { useLocation } from 'react-router-dom'

const Menu = () => {
    const location = useLocation()

    useEffect(() => {
        // navigate.listen((location) => {
            // console.log(location.pathname)
        // })
    }, [location])
    
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/" className="brand-link">
            <img src={`${process.env.PUBLIC_URL}/images/logo-white.png`} alt="Moudy Leather"  height="40" />
            <span className="brand-text font-weight-light">&nbsp;</span>
        </a>
        <div className="sidebar">
            {/* <div className="user-panel mt-3 pb-3 mb-3 d-flex">
            <div className="image">
                <img src="dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
            </div>
            <div className="info">
                <a href="#" className="d-block">Alexander Pierce</a>
            </div>
            </div> */}
            
            <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li className="nav-item">
                    <a href="/" className={`nav-link ${location.pathname === "/" ? "active" : ""}`}>
                        <i className="nav-icon fas fa-tachometer-alt" />
                        <p>
                        Dashboard
                        </p>
                    </a>
                </li>
                
                <li className="nav-header">Inventory</li>
                
                {/* <li className="nav-item">
                    <a
                        href="/departments"
                        className={`nav-link ${location.pathname.includes("/departments") ? "active" : ""}`}
                    >
                        <i className="nav-icon fas fa-tachometer-alt" />
                        <p>
                        Departments
                        </p>
                    </a>
                </li> */}

                <li className="nav-item">
                    <a href="/suppliers" className={`nav-link ${location.pathname.includes("supplier") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-truck" />
                        <p>
                        Suppliers
                        </p>
                    </a>
                </li>
                
                <li className="nav-item">
                    <a href="/products" className="nav-link">
                        <i className="nav-icon fas fa-box" />
                        <p>
                        Products
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="/warehouse" className="nav-link">
                        <i className="nav-icon fas fa-warehouse" />
                        <p>
                        Warehouse
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="#" className="nav-link">
                        <i className="nav-icon fas fa-chart-line" />
                        <p>
                        Supplier Analysis
                        </p>
                    </a>
                </li>
                
                <li className="nav-header">Administrative</li>

                <li className="nav-item">
                    <a href="/customers" className={`nav-link ${location.pathname.includes("customer") ? "active" : ""}`}>
                        <i className="nav-icon fa-regular fa-face-smile" />
                        <p>
                        Customers
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="/payment-options" className={`nav-link ${location.pathname.includes("payment-options") ? "active" : ""}`}>
                        <i className="nav-icon fa-regular fa-credit-card" />
                        <p>
                        Payment Options
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="/discounts" className={`nav-link ${location.pathname.includes("discounts") ? "active" : ""}`}>
                        <i className="nav-icon fa-regular fa-percent" />
                        <p>
                        Discounts
                        </p>
                    </a>
                </li>
                
                <li className="nav-item">
                    <a href="/branches" className={`nav-link ${location.pathname.includes("branch") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-store" />
                        <p>
                        Branches
                        </p>
                    </a>
                </li>

                <li className="nav-item">
                    <a href="/employees" className={`nav-link ${location.pathname.includes("employee") ? "active" : ""}`}>
                        <i className="nav-icon fas fa-user-tie" />
                        <p>
                        Employees
                        </p>
                    </a>
                </li>

                <li className="nav-header">Reports</li>
                
                <li className="nav-item">
                    <a href="/sales" className="nav-link">
                        <i className="nav-icon fas fa-cash-register" />
                        <p>
                        Sales
                        </p>
                    </a>
                </li>
            </ul>
            </nav>
        </div>
        </aside>
    )
}

export default Menu