import { DataGrid } from "@mui/x-data-grid"
import React, { useEffect, useState } from "react"
import Header from "../../components/Header"
import Menu from "../../components/Menu"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"
import {
    fetchBranches,
    fetchExpenses,
    fetchExpenseModes
} from "../../services"
import {
    Box
} from "@mui/material"
import Select from "react-select"
import moment from 'moment';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';

const Expenses = () => {
    const columns = [
        {
            field: "date_add",
            headerName: "Date",
            flex: 0.7,
            renderCell: (params) => {
                return <span>{moment(params.row.date_add).format("dddd,yyyy-MM-DD hh:m A")}</span>
            }
        },
        {
            field: "amount",
            headerName: "Amount",
            flex: 0.2,
            renderCell: (params) => {
                return <span>EGP {Number(params.row.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            }
        },
        {
            field: "mode_name_en",
            headerName: "Mode",
            flex: 0.5
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1
        }
    ]
    const { id } = useParams()

    const [total, setTotal] = useState(0),
    [filters, setFilters] = useState({ id })

    const { data: info, isLoading: isInfoLoading } = useQuery("info", () => fetchBranches(id), {
        select: (data) => data.branches[0],
        refetchOnWindowFocus: false
    })

    const { data: modes, isLoading: isModesLoading } = useQuery("modes", fetchExpenseModes, {
        select: (data) => data.modes.map((mode) => {
            return { label: mode.name_en, value: mode.id }
        }),
        refetchOnWindowFocus: false
    })

    const { data, isLoading, refetch } = useQuery("expenses", () => fetchExpenses(filters), {
        select: (data) => data.expenses,
        onSuccess: (data) => {
            let total = data.reduce((sum, a) => sum + a.amount, 0)
            setTotal(total)
        },
        refetchOnWindowFocus: false
    })

    const removeFilter = (key) => {
        let filtersClone = {...filters}
        delete filtersClone[key]
        setFilters(filtersClone)
    }

    useEffect(() => {
        refetch()
    }, [filters])

    const handleDateChange = (date) => {
        if (date) {
            setFilters({ ...filters, date: moment(date).format("yyyy-MM-DD") })
        } else {
            removeFilter("date")
        }
        
    }

    if (isLoading || isInfoLoading || isModesLoading) return <>Loading...</>

    return (
        <div className="wrapper">
            <Header />
            <Menu />
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1 className="m-0"><i>{info.name_en}</i>'s Expenses</h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                                {/* <li className="breadcrumb-item active">Dashboard</li> */}
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h4>EGP {total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                                    <p>TOTAL EXPENSES</p>
                                </div>

                                <div className="icon">
                                    <i className="fa fa-money-bill" />
                                </div>

                                <div className="small-box-footer text-uppercase">
                                    at {info.name_en}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9">&nbsp;</div>
                    </div>

                    <div className="row mb-5">
                        <div className="col-lg-3">
                            <Select
                                options={modes}
                                isClearable
                                placeholder="Filter by mode"
                                defaultValue={filters?.mode_id}
                                onChange={(value, triggeredAction) => {
                                    if (value && value.value) {
                                        setFilters({...filters, mode_id: value.value})
                                    }

                                    if (triggeredAction.action === "clear") {
                                        removeFilter("mode_id")
                                    }
                                }}
                            />
                        </div>

                        <div className="col-lg-3">
                            <DatePicker onChange={handleDateChange} value={filters?.date} />
                        </div>
                    </div>


                    <Box sx={{ height: "62vh", width: "100%" }}>
                        <DataGrid
                            columns={columns}
                            rows={data}
                        />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Expenses