import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { fetchProducts } from "../../services";

const Products = () => {
  const { data: products, isLoading } = useQuery(
    "products",
    () => fetchProducts({}),
    {
      select: (data) => {
        return data.products;
      },
      refetchOnWindowFocus: false
    }
  );

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Products <span className="text-muted text-sm">({products?.length} results found)</span></h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                  {/* <li className="breadcrumb-item active">Dashboard</li> */}
                </ol>
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
            <div className="row mb-4">
              <div className="col-lg-3">
                <a href="/new-product" className="btn btn-primary btn-sm">
                  New product
                </a>
              </div>
            </div>

            <Box sx={{ height: "100vh", position: "relative", overFlowY: "hidden", width: "100%" }}>
              {isLoading ? (
                <>Loading...</>
              ) : (
                
                  <div className="row">
                    {products.map((p) => {
                      return (
                        <div className="col-lg-2" key={p.id}>
                          <div className="card bg-dark">
                            <div className="card-body">
                              <h4 className="card-title mb-1">{p.sku}</h4>
                              <p className="card-text blockquote-footer">{p.supplier_name_en}, {p.department_name_en}</p>
                              <a
                                href={`/variations/${p.id}`}
                                className="btn btn-warning"
                              >
                                View
                              </a>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                
              )}
            </Box>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Products;
