import React from "react"
import {
    BrowserRouter as Router,
    Routes as Switch,
    Route,
    Navigate
} from "react-router-dom"
import { getToken } from "./utils"

import Login from "./pages/Login"
import Dashboard from "./pages/Dashboard"
import Suppliers from "./pages/Suppliers"
import SupplierDepartments from "./pages/Suppliers/Departments"
import LockScreen from "./pages/LockScreen"
import AddSupplier from "./pages/Suppliers/AddSupplier"
import AddDepartment from "./pages/Suppliers/AddDepartment"
import Branches from "./pages/Branches"
import AddBranch from "./pages/Branches/AddBranch"
import Employees from "./pages/Employees"
import AddEmployee from "./pages/Employees/AddEmployee"
import EditSupplier from "./pages/Suppliers/EditSupplier"
import Products from "./pages/Products"
import AddProduct from "./pages/Products/AddProduct"
import Variations from "./pages/Products/Variations"
import AddVariation from "./pages/Products/AddVariation"
import Discounts from "./pages/Discounts"
import VariationStock from "./pages/Products/VariationStock"
import EditVariation from "./pages/Products/EditVariation"
import Sales from "./pages/Sales"
import Expenses from "./pages/Branches/Expenses"
import Warehouse from "./pages/Warehouse"
import Customers from "./pages/Customers"

const ProtectedRoute = ({ children }) => {
    const isAuth = getToken()

    if (!isAuth) return <Navigate to="/login" />
    return children
}

const UnprotectedRoute = ({ children }) => {
    const isAuth = getToken()
    
    if (isAuth) return <Navigate to="/" />
    return children
}

export const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact element={<ProtectedRoute>
                    <Dashboard />
                </ProtectedRoute>} />
                <Route path="/suppliers" element={<ProtectedRoute>
                    <Suppliers />
                </ProtectedRoute>} />
                <Route path="/supplier-departments/:supplier_id" element={<ProtectedRoute>
                    <SupplierDepartments />
                </ProtectedRoute>} />
                <Route path="/lock" element={<UnprotectedRoute>
                    <LockScreen />
                </UnprotectedRoute>} />
                <Route path="/new-supplier" element={<ProtectedRoute>
                    <AddSupplier />
                </ProtectedRoute>} />
                <Route path="/edit-supplier/:supplier_id" element={<ProtectedRoute>
                    <EditSupplier />
                </ProtectedRoute>} />
                <Route path="/new-department" element={<ProtectedRoute>
                    <AddDepartment />
                </ProtectedRoute>} />
                <Route path="/branches" element={<ProtectedRoute>
                    <Branches />
                </ProtectedRoute>} />
                <Route path="/new-branch" element={<ProtectedRoute>
                    <AddBranch />
                </ProtectedRoute>} />
                <Route path="/employees" element={<ProtectedRoute>
                    <Employees />
                </ProtectedRoute>} />
                <Route path="/new-employee" element={<ProtectedRoute>
                    <AddEmployee />
                </ProtectedRoute>} />
                <Route path="/products" element={<ProtectedRoute>
                    <Products />
                </ProtectedRoute>} />
                <Route path="/new-product" element={<ProtectedRoute>
                    <AddProduct />
                </ProtectedRoute>} />
                <Route path="/variations/:product_id" element={<ProtectedRoute>
                    <Variations />
                </ProtectedRoute>} />
                <Route path="/variations/stock/:variation_id" element={<ProtectedRoute>
                    <VariationStock />
                </ProtectedRoute>} />
                <Route path="/variations/edit/:variation_id" element={<ProtectedRoute>
                    <EditVariation />
                </ProtectedRoute>} />
                <Route path="/sales" element={<ProtectedRoute>
                    <Sales />
                </ProtectedRoute>} />
                <Route path="/branch-expenses/:id" element={<ProtectedRoute>
                    <Expenses />
                </ProtectedRoute>} />
                <Route path="/warehouse" element={<ProtectedRoute>
                    <Warehouse />
                </ProtectedRoute>} />
                <Route path="/customers" element={<ProtectedRoute>
                    <Customers />
                </ProtectedRoute>} />
                <Route path="/discounts" element={<ProtectedRoute>
                    <Discounts />
                </ProtectedRoute>} />
                {/* <Route path="/new-variation/:product_id" element={<ProtectedRoute>
                    <AddVariation />
                </ProtectedRoute>} /> */}
                <Route path="/login" element={<UnprotectedRoute>
                    <Login />
                </UnprotectedRoute>} />
                {/* <Route component={NotFound} /> */}
            </Switch>
        </Router>
    )
}