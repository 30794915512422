import React, { useState } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Select from "react-select";
import { fetchSuppliers, saveProduct, fetchDepartments, fetchCategories } from "../../services";
import { useQuery } from "react-query";
import { Formik } from "formik";
import * as Yup from "yup";

const AddProduct = () => {
  const [selectedDep, setSelectedDep] = useState(null),
  [categories, setCategories] = useState([]),
  [autoSku, setAutoSku] = useState(null)

  const { data: suppliers, isLoading } = useQuery(
    ["suppliers"],
    () => fetchSuppliers(),
    {
      select: (data) =>
        data.suppliers.map((s) => {
          return { label: s.name_en, value: s.id };
        }),
      refetchOnWindowFocus: false,
    }
  );

  const loadSuggestedSKU = async (categoryId) => {
    let value = categories.find(x => x.value === categoryId)
    // setAutoSku(value.suggestedSKU)
    return value.suggestedSKU
  }

  const loadCategories = async (supplierId) => {
    let results = await fetchCategories(supplierId)

    let x = results.categories?.map((c) => {
      return { label: `${c.department_name_en} ${c.category_name_en}`, value: c.category_id, suggestedSKU: c.newCode }
    })
    setCategories(x)
  }

  /*const { data: departments, isDepartmentsLoading } = useQuery(
    ["departments"],
    () => fetchDepartments(),
    {
      select: (data) =>
        data.departments.map((s) => {
          return { label: s.name_en, value: s.id };
        }),
      refetchOnWindowFocus: false,
    }
  );*/

  /*const updateDepartment = async (value) => {
    let results = await fetchCategories(value)
    let x = results.categories.map((c) => {
      return { label: c.name_en, value: c.id }
    })
    setCategories()
  }*/

  const submitProduct = async (info) => {
    try {
      // console.log(info)
      await saveProduct(info);
      window.location.href = "/products";
    } catch (e) {
      console.log(e);
    }
  };

  // if (isLoading || isDepartmentsLoading) return <>Loading...</>;
  if (isLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">New Product</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/products">Products</a>
                  </li>
                  <li className="breadcrumb-item active">New product</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Product Info</h3>
                  </div>

                  <Formik
                    // enableReinitialize
                    // initialValues={{ name_en: "", name_ar: "", dep_ids: [] }}
                    initialValues={{
                      sku: "",
                      cost: "",
                      supplier_id: "",
                      // dep_id: "",
                      description_en: "",
                      description_ar: "",
                      category_id: ""
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      submitProduct(values);
                      // console.log(values)
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      sku: Yup.string().optional(),
                      cost: Yup.number().required(),
                      supplier_id: Yup.number().required(),
                      // dep_id: Yup.number().required(),
                      category_id: Yup.number().required(),
                      description_en: Yup.string().required(),
                      description_ar: Yup.string().required(),
                      /*dep_ids: Yup.string().required(
                        "Please select at least one department"
                      ),*/
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>SKU</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Product SKU"
                                    onChange={({target}) => {
                                      if (target.value !== null) {
                                        setFieldValue("sku", target.value)
                                        console.log("Done")
                                      }else {
                                        console.log("Oooops")
                                        setFieldValue("sku", autoSku)
                                      }

                                    }}
                                    name="sku"
                                    defaultValue={values.sku || autoSku}
                                    // value={values.sku}
                                  />
                                  {errors.sku && touched.sku && (
                                    <span className="text-danger text-sm">
                                      {errors.sku}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Cost</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Unit Cost"
                                    onChange={handleChange}
                                    name="cost"
                                    value={values.cost}
                                  />
                                  {errors.cost && touched.cost && (
                                    <span className="text-danger text-sm">
                                      {errors.cost}
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="form-group">
                              <div className="row">
                                <div className="col-lg-6">
                                  <label>Supplier</label>
                                  <Select
                                    closeMenuOnSelect={true}
                                    name="supplier_id"
                                    defaultValue={suppliers.find((d) => {
                                      return d.value === props.values.id
                                        ? d.id
                                        : null;
                                    })}
                                    onChange={({ value }) => {
                                      setFieldValue("supplier_id", value);
                                      loadCategories(value)
                                    }}
                                    onBlur={handleBlur}
                                    options={suppliers}
                                  />
                                  {errors.supplier_id && touched.supplier_id && (
                                    <span className="text-danger text-sm">
                                      {errors.supplier_id}
                                    </span>
                                  )}
                                </div>

                                <div className="col-lg-6">
                                  <label>Category</label>

                                  <Select
                                    closeMenuOnSelect={true}
                                    name="category_id"
                                    defaultValue={categories?.find((d) => {
                                      return d.value === props.values.id
                                        ? d.id
                                        : null;
                                    })}
                                    onChange={async ({ value }) => {
                                      setFieldValue("category_id", value);

                                      // Set auto-sku only when there is no SKU added
                                      if (!values.sku.length) {
                                        let newCode = await loadSuggestedSKU(value)
                                        setFieldValue("sku", newCode.toString())
                                      }
                                    }}
                                    onBlur={handleBlur}
                                    options={categories}
                                  />
                                  {errors.category_id && touched.category_id && (
                                    <span className="text-danger text-sm">
                                      {errors.category_id}
                                    </span>
                                  )}
                                </div>

                                {/*<div className="col-lg-6">
                                  <label>Department</label>
                                  <Select
                                    closeMenuOnSelect={true}
                                    name="dep_id"
                                    defaultValue={suppliers.find((d) => {
                                      return d.value === props.values.id
                                        ? d.id
                                        : null;
                                    })}
                                    onChange={({ value }) => {
                                      setFieldValue("dep_id", value);
                                      // updateDepartment(value)
                                    }}
                                    onBlur={handleBlur}
                                    options={departments}
                                  />
                                  </div>*/}
                              </div>
                            </div>

                            <div className="form-group">
                                <div className="row">
                                  <div className="col-lg-6">
                                    <label>Description (EN)</label>

                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Item Description (EN)"
                                      onChange={handleChange}
                                      name="description_en"
                                      value={values.description_en}
                                    />
                                    {errors.description_en && touched.description_en && (
                                    <span className="text-danger text-sm">
                                      {errors.description_en}
                                    </span>
                                  )}
                                  </div>

                                  <div className="col-lg-6">
                                    <label>Description (AR)</label>

                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Item Description (AR)"
                                      onChange={handleChange}
                                      name="description_ar"
                                      value={values.description_ar}
                                    />
                                    {errors.description_ar && touched.description_ar && (
                                    <span className="text-danger text-sm">
                                      {errors.description_ar}
                                    </span>
                                  )}
                                  </div>
                                </div>
                              </div>

                              {/* <div className="form-group">
                              <div className="row">
                                  <div className="col-lg-6">
                                  <label>Category</label>

                                  <Select
                                    closeMenuOnSelect={true}
                                    name="category_id"
                                    defaultValue={categories?.find((d) => {
                                      return d.value === props.values.id
                                        ? d.id
                                        : null;
                                    })}
                                    onChange={({ value }) => {
                                      setFieldValue("category_id", value);
                                    }}
                                    onBlur={handleBlur}
                                    options={categories}
                                  />
                                </div>
                              </div>
                          </div> */}
                          </div>

                          <div className="card-footer">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="btn btn-primary"
                            >
                              Add Product
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
