import React, { useEffect } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery } from "react-query";
import {fetchSupplierDepartments} from "../../services"
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";

const Departments = () => {
    const { supplier_id } = useParams();

    const { data: departments, isLoading } = useQuery(
        ["suppliers"],
        () => fetchSupplierDepartments(supplier_id),
        {
          select: (data) => {
            console.log(data.departments)
            return data.departments
          }
        }
      );

      const columns: GridColDef[] = [
        { field: 'name_en', headerName: 'Name (EN)', flex: 0.5 },
        { field: 'name_ar', headerName: 'Name (AR)', flex: 0.5 },
        { field: 'date_add', headerName: 'Date Added', flex: 1 },
        { field: 'modified', headerName: 'Last Modified', flex: 1 },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="d-flex flex-row justify-content-space-between align-items-center">
                        <a
                          className="btn btn-sm btn-default mr-2"
                          href={`/supplier/${params.row.id}`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                        >
                            <i className="fa fa-edit" />
                        </a>
    
                        <button
                          className={`btn btn-sm btn-${parseInt(params.row.active) ? "danger" : "success"}`}
                          data-toggle="tooltip"
                          data-placement="top"
                          title={`${parseInt(params.row.active) ? `Deactivate` : `Activate`}`}
                        >
                            <i className={`fa fa-${parseInt(params.row.active) ? "times" : "check"}`} />
                        </button>
                    </div>
                )
            }
        },
      ]

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Suppliers</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                  {/* <li className="breadcrumb-item active">Dashboard</li> */}
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <Box sx={{ height: 400, width: "100%" }}>
              {isLoading ? (
                "Loading"
              ) : (
                <DataGrid
                  rows={departments}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  checkboxSelection
                  disableRowSelectionOnClick
                />
              )}
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Departments;
