import React, { useEffect, useState } from "react"
import Select from "react-select"
import CreatableSelect from 'react-select/creatable';
import { useQuery } from "react-query"
import {
    Box,
    Button
} from "@mui/material"
import { capitalize } from "../../utils"
import Header from "../../components/Header"
import Menu from "../../components/Menu"
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from '@mui/material/Divider';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import {
    fetchImports, fetchProducts, fetchSuppliers, fetchVariations, importInventory
} from "../../services"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

const Warehouse = () => {
    const [activeImport, setActiveImport] = useState(null),
        [metaOpen, setMetaOpen] = useState(false),
        [filters, setFilters] = useState({}),
        [importOpen, setImportOpen] = useState(false),
        [returnOpen, setReturnOpen] = useState(false),
        [supplierId, setSupplierId] = useState(null),
        [supplierProducts, setSupplierProducts] = useState(null),
        // [productId, setProductId] = useState(null),
        // [colors, setColors] = useState([]),
        // [newColor, setNewColor] = useState(null),
        // [colorArVisible, setColorArVisible] = useState(false),
        // [colorAr, setColorAr] = useState(null),
        [isCreatingNewColor, setIsCreatingNewColor] = useState(false)

    const importsLogColumns = [
        // {
        //     field: "department_name_en",
        //     headerName: "Department",
        //     flex: 1
        // },
        {
            field: "supplier_name_en",
            headerName: "Supplier",
            flex: 1
        },
        {
            field: "formatted_date_add",
            headerName: "Date",
            flex: 1
        },
        // {
        //     field: "sku",
        //     headerName: "SKU",
        //     flex: 1
        // },
        {
            field: "total_cost",
            headerName: "Total Cost",
            flex: 1,
            renderCell: (params) => {
                return <span><small>EGP</small> {Number(params.row.total_cost).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            }
        },
        {
            field: "total_qty",
            headerName: "Total Qty",
            flex: 1
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            renderCell: (params) => {
                return (
                    <div className="flex-row justify-content-space-between align-items-center">
                        <button
                            className="btn btn-sm bg-gradient-success"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Details"
                            onClick={() => {
                                setActiveImport(params.row)
                                setMetaOpen(true)
                            }}
                        >
                            <i className="fa fa-eye" />
                        </button>
                    </div>
                )
            }
        }
    ]

    const { data, isLoading } = useQuery("imports", () => fetchImports(filters), {
        select: (data) => data.imports,
        refetchOnWindowFocus: false
    })

    const { data: suppliers, isLoading: isLoadingSuppliers } = useQuery("suppliers", () => fetchSuppliers(), {
        select: (data) => data.suppliers.map((s) => {
            return { label: s.name_en, value: s.id }
        }),
        refetchOnWindowFocus: false
    })

    const loadSkus = async (supplier_id) => {
        try {
            let products = await fetchProducts({ supplier_id })
            setSupplierProducts(products.products.map((p) => {
                return { label: p.sku, value: p.id }
            }))
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
        }
    }

    /*const loadColors = async () => {
        try {
            let variations = await fetchVariations(productId)
            let colorOptions = []

            variations.variations.map((v) => {
                if (!colorOptions.some(e => e.value === v.color))
                    colorOptions.push({ label: `${v.color}`, value: v.color, color_ar: v.color_ar })
            })

            setColors(colorOptions)
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
        }
    }*/

    const saveImport = async (values) => {
        try {
            /*await importInventory({
                product_id: productId,
                color: newColor.value,
                color_ar: colorAr,
                ...values
            })*/

            await importInventory(values)

            alert("Inventory has been successfully updated")
            window.location.href = `/variations/${values.newImports[0].product_id}`
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
        }
    }

    const handleCreate = async (inputValue, index) => {
        // setIsCreatingNewColor(true);
        // const newOption = { label: inputValue, value: inputValue };
        // setIsCreatingNewColor(false);
        // setColors((prev) => [...prev, newOption]);
        // setColorArVisible(true)
        // setNewColor(newOption);
    }

    /*useEffect(() => {
        if (supplierId)
            loadSkus()
    }, [supplierId])*/

    // useEffect(() => {
    //     if (productId)
    //         loadColors()
    // }, [productId])

    if (isLoadingSuppliers || isLoading) return <>Loading...</>

    return (
        <div className="wrapper">
            <Header />
            <Menu />
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Warehouse</h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                                {/* <li className="breadcrumb-item active">Dashboard</li> */}
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="content">
                    {/* <div className="row">
                        <div className="col-lg-3">
                            <div className="small-box bg-warning">
                                <div className="inner">
                                    <h4>EGP {total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                                    <p>TOTAL INVANTORY COST</p>
                                </div>

                                <div className="icon">
                                    <i className="fa fa-money-bill" />
                                </div>

                                <div className="small-box-footer text-uppercase">
                                    at {info.name_en}
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-9">&nbsp;</div>
                    </div> */}

                    <div className="row mb-5">
                        <div className="col-lg-2">
                            <button
                                className="btn btn-block bg-gradient-primary"
                                onClick={() => setImportOpen(true)}
                            >
                                <i className="fa fa-upload mr-2" /> Import inventory
                            </button>
                        </div>

                        <div className="col-lg-2">
                            <button
                                className="btn btn-block bg-gradient-danger"
                                onClick={() => setImportOpen(true)}
                            >
                                <i className="fa fa-right-left mr-2" /> Return inventory
                            </button>
                        </div>
                    </div>

                    <BootstrapDialog
                        onClose={() => setMetaOpen(false)}
                        aria-labelledby="customized-dialog-title"
                        open={metaOpen}
                        //fullScreen={true}
                        fullWidth={true}
                        maxWidth={"md"}
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            {`Import # ${activeImport?.id} details`}
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={() => setMetaOpen(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <DialogContent dividers>
                            <table style={{ width: "100%" }}>
                                <thead>
                                    <tr style={{ backgroundColor: "#f5f5f5" }}>
                                        <th>Color</th>
                                        <th>Size</th>
                                        <th>Unit Cost</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        activeImport && activeImport.meta && activeImport.meta.results && activeImport.meta.results.map((item) => {
                                            return (<tr style={{ height: 50 }}>
                                                <td>{item.color}</td>
                                                <td>{item.size} <small>x{item.qty}</small></td>
                                                <td>{Number(item.unit_cost).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                                            </tr>)
                                        })
                                    }
                                </tbody>

                                {/* <tfoot style={{ backgroundColor: "#f5f5f5" }}>
                            <tr>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                            </tr>
                        </tfoot> */}
                            </table>
                        </DialogContent>
                    </BootstrapDialog>

                    <BootstrapDialog
                        onClose={() => setImportOpen(false)}
                        aria-labelledby="customized-dialog-title"
                        // fullWidth={true}
                        // maxWidth={"md"}
                        fullScreen={true}
                        open={importOpen}
                    >
                        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                            Import new inventory
                        </DialogTitle>
                        <IconButton
                            aria-label="close"
                            onClick={() => setImportOpen(false)}
                            sx={{
                                position: "absolute",
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>

                        <Formik
                            initialValues={{
                                is_history: false,
                                supplier_id: "",
                                newImports: [
                                    {
                                        product_id: "",
                                        color: "",
                                        colors: [],
                                        color_ar: "",
                                        isNewColor: false,
                                        newVariations: [{ size: "", qty: "", unit_cost: "", price: "" }]
                                    }
                                ]
                                // newVariations: [{ size: "", qty: "", cost: "" }]
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                // Prepare values for submission
                                let data = JSON.parse(JSON.stringify(values)); 
                                data.supplier_id = data.supplier_id.value || data.supplier_id
                                data.newImports.map((entry) => {
                                    entry.color = entry.color.value || entry.color
                                    entry.product_id = entry.product_id.value || entry.product_id

                                    // delete entry.isNewColor
                                    delete entry.colors
                                })
                                saveImport(data)
                                setSubmitting(false);
                            }}
                        // validationSchema={Yup.object().shape({
                        //     color: Yup.string().required(),
                        //     size: Yup.number().required(),
                        //     qty: Yup.number().required(),
                        //     unit_cost: Yup.number().required()
                        // })}
                        >
                            {(props) => {
                                const {
                                    values,
                                    touched,
                                    errors,
                                    isSubmitting,
                                    setFieldValue,
                                    handleChange,
                                    handleReset,
                                    handleBlur,
                                } = props;

                                return (
                                    <Form>
                                        <DialogContent dividers sx={{ height: "85vh" }}>
                                            <FieldArray
                                                name="newImports"
                                                render={(arrayHelpers) => (
                                                    <>
                                                        <div className="form-group">
                                                            <label>Supplier</label>
                                                            <Select
                                                                options={suppliers}
                                                                isClearable
                                                                name="supplier_id"
                                                                value={values.supplier_id}
                                                                // defaultValue={supplierId || ""}
                                                                onChange={(value, triggeredAction) => {
                                                                    if (value && value.value) {
                                                                        setFieldValue("supplier_id", value)
                                                                        loadSkus(value.value)
                                                                        // setSupplierId(value.value)
                                                                    }

                                                                    if (triggeredAction.action == "clear") {
                                                                        handleReset()
                                                                    }
                                                                        // setSupplierId(null)
                                                                }}
                                                            />
                                                        </div>

                                                        {
                                                            values.newImports && values.newImports.length
                                                                ?
                                                                values.newImports.map((imp, impIndex) => (
                                                                    <div className="mb-5"  key={impIndex}>
                                                                        <div className="form-group">
                                                                            <div className="row">
                                                                                <div className="col-lg-2">
                                                                                    <label>SKU</label>
                                                                                    <Select
                                                                                        options={supplierProducts || []}
                                                                                        isClearable
                                                                                        // value={imp.product_id || ""}
                                                                                        value={values.newImports[impIndex].product_id || ""}
                                                                                        name={`newImports[${impIndex}].product_id`}
                                                                                        onChange={async (value, triggeredAction) => {
                                                                                            if (value && value.value) {
                                                                                                setFieldValue(`newImports[${impIndex}].product_id`, value)
                                                                                                let productColors = await fetchVariations(value.value),
                                                                                                colorOptions = []

                                                                                                productColors.variations.map((v) => {
                                                                                                    if (!colorOptions.some(e => e.value === v.color))
                                                                                                        colorOptions.push({ label: `${v.color}`, value: v.color, color_ar: v.color_ar })

                                                                                                    setFieldValue(`newImports[${impIndex}].colors`, colorOptions)
                                                                                                })
                                                                                            }

                                                                                            if (triggeredAction.action == "clear") {
                                                                                                setFieldValue(`newImports[${impIndex}].colors`, [])
                                                                                                // setProductId(null)
                                                                                                setFieldValue(`newImports[${impIndex}].product_id`, "")
                                                                                                setFieldValue(`newImports[${impIndex}].color`, "")
                                                                                                setFieldValue(`newImports[${impIndex}].color_ar`, "")
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                <div className={`col-lg-2`}>
                                                                                    <label>Color</label>
                                                                                    <CreatableSelect
                                                                                        options={values.newImports[impIndex].colors || []}
                                                                                        isClearable
                                                                                        isDisabled={isCreatingNewColor}
                                                                                        isLoading={isCreatingNewColor}
                                                                                        name={`newImports[${impIndex}].color`}
                                                                                        // value={newColor}
                                                                                        value={values.newImports[impIndex].color}
                                                                                        // defaultValue={values.newImports[impIndex].color}
                                                                                        onCreateOption={(value) => {
                                                                                            setIsCreatingNewColor(true);
                                                                                            setTimeout(() => {
                                                                                                let newColorObj = { label: capitalize(value), value: capitalize(value), color_ar: capitalize(value) }
                                                                                                setIsCreatingNewColor(false);
                                                                                                let colorOptions = values.newImports[impIndex].colors
                                                                                                colorOptions.push(newColorObj)
                                                                                                setFieldValue(`newImports[${impIndex}].colors`, colorOptions)
                                                                                                setFieldValue(`newImports[${impIndex}].color`, newColorObj)
                                                                                                setFieldValue(`newImports[${impIndex}].isNewColor`, true)
                                                                                            }, 1000);
                                                                                        }}
                                                                                        onChange={(value, triggeredAction) => {
                                                                                            if (value && value.value) {
                                                                                                let colorObj = values.newImports[impIndex].colors.filter(c => {
                                                                                                    return c.value == value.value
                                                                                                })

                                                                                                setFieldValue(`newImports[${impIndex}].color`, colorObj[0])
                                                                                                setFieldValue(`newImports[${impIndex}].color_ar`, colorObj[0].color_ar)
                                                                                            }

                                                                                            if (triggeredAction.action == "clear") {
                                                                                                setFieldValue(`newImports[${impIndex}].color`, "")
                                                                                                setFieldValue(`newImports[${impIndex}].color_ar`, "")
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                                {/* {colorArVisible ? */}
                                                                                <div className={`col-lg-2`}>
                                                                                    <label>Color (AR)</label>
                                                                                    <Field
                                                                                        name={`newImports[${impIndex}].color_ar`}
                                                                                        onChange={({ target }) => {
                                                                                            // setColorAr(target.value)
                                                                                            setFieldValue(`newImports[${impIndex}].color_ar`, target.value)
                                                                                        }}
                                                                                        placeholder="Color (AR)"
                                                                                        className="form-control"
                                                                                        disabled={!values.newImports[impIndex].isNewColor}
                                                                                        value={values.newImports[impIndex].color_ar}
                                                                                    // value={imp[impIndex].color_ar || ""}
                                                                                    />
                                                                                </div>
                                                                                {/* : <></>} */}

                                                                                <div className="col-lg-1">
                                                                                    <label className="d-block">&nbsp;</label>
                                                                                    {impIndex === 0 ? (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-default"
                                                                                            onClick={() => {
                                                                                                arrayHelpers.push(arrayHelpers.form.initialValues.newImports[0])
                                                                                                console.log(arrayHelpers.form.values)
                                                                                            }}
                                                                                        >
                                                                                            <i className="fa fa-plus" />
                                                                                        </button>
                                                                                    ) : (
                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-danger"
                                                                                            onClick={() =>
                                                                                                arrayHelpers.remove(impIndex)
                                                                                            }
                                                                                        >
                                                                                            <i className="fa fa-minus" />
                                                                                        </button>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <FieldArray
                                                                            name={`newImports[${impIndex}].newVariations`}
                                                                            render={(variationHelpers) => (
                                                                                <div className="form-group" key={impIndex}>
                                                                                    <div className="row mb-2">
                                                                                        <div className="col-lg-1 text-bold">Size</div>
                                                                                        <div className="col-lg-1 text-bold">Qty</div>
                                                                                        <div className="col-lg-2 text-bold">Unit Cost</div>
                                                                                        <div className="col-lg-1 text-bold">Price</div>
                                                                                    </div>

                                                                                    {imp.newVariations && imp.newVariations.length
                                                                                        ?
                                                                                        imp.newVariations.map((v, index) => (
                                                                                            <div className="form-group" key={index}>
                                                                                                <div className="row">
                                                                                                    <div className="col-lg-1">
                                                                                                        <Field
                                                                                                            type="number"
                                                                                                            className="form-control"
                                                                                                            value={imp.newVariations[index].size || ""}
                                                                                                            onChange={handleChange}
                                                                                                            name={`newImports[${impIndex}].newVariations[${index}].size`}
                                                                                                            placeholder={`Size`}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div className="col-lg-1">
                                                                                                        <Field
                                                                                                            type="number"
                                                                                                            className="form-control"
                                                                                                            value={imp.newVariations[index].qty || ""}
                                                                                                            onChange={handleChange}
                                                                                                            name={`newImports[${impIndex}].newVariations[${index}].qty`}
                                                                                                            placeholder={`Qty`}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div className="col-lg-2">
                                                                                                        <Field
                                                                                                            type="number"
                                                                                                            className="form-control"
                                                                                                            value={imp.newVariations[index].unit_cost || ""}
                                                                                                            onChange={handleChange}
                                                                                                            name={`newImports[${impIndex}].newVariations[${index}].unit_cost`}
                                                                                                            placeholder={`Unit Cost`}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div className="col-lg-1">
                                                                                                        <Field
                                                                                                            type="number"
                                                                                                            className="form-control"
                                                                                                            value={imp.newVariations[index].price || ""}
                                                                                                            onChange={handleChange}
                                                                                                            name={`newImports[${impIndex}].newVariations[${index}].price`}
                                                                                                            placeholder={`Price`}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div className="col-lg-1">
                                                                                                        {index === 0 ? (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-default"
                                                                                                                onClick={() => variationHelpers.push("")}
                                                                                                            >
                                                                                                                <i className="fa fa-plus" />
                                                                                                            </button>
                                                                                                        ) : (
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-danger"
                                                                                                                onClick={() =>
                                                                                                                    variationHelpers.remove(index)
                                                                                                                }
                                                                                                            >
                                                                                                                <i className="fa fa-minus" />
                                                                                                            </button>
                                                                                                        )}
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        ))
                                                                                        :
                                                                                        (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-sm bg-gradient-dark"
                                                                                                onClick={() => {
                                                                                                    variationHelpers.push("")
                                                                                                }}
                                                                                            >
                                                                                                <i className="fa fa-plus" />
                                                                                            </button>
                                                                                        )}
                                                                                </div>

                                                                            )}
                                                                        />

                                                                        {impIndex < values.newImports.length - 1 ? <Divider /> : null}
                                                                    </div>
                                                                ))

                                                                :

                                                                (
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => arrayHelpers.push("")}
                                                                    >
                                                                        <i className="fa fa-plus" />
                                                                    </button>
                                                                )
                                                        }

                                                        {/* <Divider sx={{ mb: 2 }} /> */}


                                                    </>
                                                )}
                                            />

                                            {/* {colorAr ? <></> : ( */}
                                                <>
                                                    <Divider />

                                                    <FormGroup>
                                                        <FormControlLabel
                                                            control={<Checkbox
                                                                name="is_history"
                                                                value={values.is_history}
                                                                onChange={handleChange}
                                                            />}
                                                            size="large"
                                                            label="History import ? (current stock will not be updated)"
                                                        />
                                                    </FormGroup>
                                                </>
                                            {/* )} */}
                                        </DialogContent>

                                        <DialogActions>
                                            <button
                                                autoFocus
                                                className="btn bg-gradient-primary"
                                                type="submit"
                                                disabled={isSubmitting}
                                            >
                                                Proceed
                                            </button>
                                        </DialogActions>
                                    </Form>
                                );
                            }}
                        </Formik>

                    </BootstrapDialog>

                    <Box sx={{ height: "85vh", width: "100%" }}>
                        <DataGrid
                            rows={data}
                            columns={importsLogColumns}
                        />
                    </Box>
                </div>
            </div>
        </div>
    )
}

export default Warehouse