import React, { useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { fetchCustomers } from "../../services";
import { Chip } from "@mui/material";

const columns = [
    {
        field: "name",
        headerName: "Name",
        flex: 0.2,
        renderCell: (params) => {
            return <span>{params.row.firstname} {params.row.lastname}</span>
        }
    },
    {
        field: "phone_number",
        headerName: "Mobile",
        flex: 0.2,
    },
    {
        field: "recurring",
        headerName: "Recurring",
        flex: 0.15,
        renderCell: (params) => {
            let recurring = params.row.n_purchases > 1
            return recurring ? <Chip color="success" size="small" label={`YES`} /> : `-`
        }
    },
    {
        field: "n_purchases",
        headerName: "# Purchases",
        flex: 0.15,
    },
    {
        field: "total_purchase_amount",
        headerName: "Total Spent",
        flex: 0.2,
        renderCell: (params) => {
            return <Chip color="info" size="small" label={`EGP ${Number(params.row.total_purchase_amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
        }
    },
    {
        field: "created_at_branch_name_en",
        headerName: "Create at",
        flex: 0.3,
    },
    {
        field: "formatted_date_add",
        headerName: "Create On",
        flex: 0.3,
    },
    {
        field: "last_purchase_date",
        headerName: "Last Purchase",
        flex: 0.3
    },
]

const Customers = () => {
    const [filters, setFilters] = useState({})

    const { data, isLoading } = useQuery(
        "customers",
        () => fetchCustomers(filters),
        {
            select: (data) => data.customers,
            refetchOnWindowFocus: false
        }
    );

    return (
        <div className="wrapper">
            <Header />
            <Menu />
            <div className="content-wrapper">
                <div className="content-header">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Customers <span className="text-muted text-sm">({data?.length} results found)</span></h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                                {/* <li className="breadcrumb-item active">Dashboard</li> */}
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <Box sx={{ height: "85vh", position: "relative", overFlowY: "hidden", width: "100%" }}>
                        {isLoading ? (
                            <>Loading...</>
                        ) : (
                            <DataGrid
                                rows={data}
                                columns={columns}
                            />
                        )}
                    </Box>
                </div>
            </div>
        </div>
    );
};

export default Customers;
