import React, {  } from 'react';
import Header from '../../components/Header';
import Menu from '../../components/Menu';

const AddEmployee = () =>  {
    return (
        <div className="wrapper">
            <Header />
        <Menu />
        <div className="content-wrapper">
      <div className="content-header">
        <div className="container">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">New Employee</h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <a href="/employees">Employees</a>
                </li>
                <li className="breadcrumb-item active">New employee</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Employee Info</h3>
                </div>
                <form>
                  <div className="card-body">
                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-6">
                                <label>First name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Employee first name"
                                />
                            </div>

                            <div className="col-lg-6">
                                <label>Last name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Employee last name"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="row">
                            <div className="col-lg-6">
                                <label>Username</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Username"
                                />
                            </div>

                            <div className="col-lg-6">
                                <label>Password</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    placeholder="Password"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                      <label>Branche(s)</label>
                      <select className="form-control" multiple>
                        <option>Giza</option>
                        <option>Madinaty</option>
                        <option>City Center Almaza</option>
                      </select>
                    </div>
                    
                  </div>
                  <div className="card-footer">
                    <button type="submit" className="btn btn-primary">
                      Add Employee
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    )
}

export default AddEmployee