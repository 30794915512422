import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQuery, useQueries } from "react-query";
import { useParams } from "react-router-dom";
import { Formik, FieldArray, Field, Form } from "formik";
import * as Yup from "yup";
import { fetchVariationInfo, fetchVariations, saveBranchStock } from "../../services";

// const columns: GridColDef[] = [
//   {
//     field: "branch_name_en",
//     headerName: "Branch",
//     width: 350,
//     // flex: 0.1,
//     sortable: false,
//     filterable: false,
//     hideable: false,
//     align: "left",
//     headerAlign: "left",
//   },
//   {
//     field: "branch_qty",
//     headerName: "Qty.",
//     sortable: true,
//     width: 100,
//     // flex: 0.1,
//     headerAlign: "left",
//     align: "left",
//     renderCell: (params) => {
//       return (
//         <input
//           className="form-control"
//           key={params.row.id}
//           placeholder="Qty."
//           defaultValue={params.row.qty}
//           name={`${params.row.id}`}
//         />
//       );
//     },
//   },
// ];

const VariationStock = () => {
  const { variation_id } = useParams();

  const variationQuery = useQuery({
    queryKey: ["info"],
    queryFn: () => fetchVariations(null, variation_id),
    select: (data) => {
      return data.variations;
    },
    refetchOnWindowFocus: false,
  });

  const stockQuery = useQuery({
    queryKey: ["stock"],
    queryFn: () => fetchVariationInfo(variation_id),
    select: (data) => {
      return data.info;
    },
    refetchOnWindowFocus: false,
  });

  const updateBranchStock = async (values, variationId) => {
    try {
        await saveBranchStock(values, variationId)
        // window.location.reload()
        window.location.href = `/variations/${variationQuery.data[0].product_id}`
    } catch(e) {
        if (e.data.message) alert(e.data.message)
    }
  }

  //   const { data: variation, isLoading } = useQuery(
  //     "variation",
  //     () => fetchVariationInfo(variation_id),
  //     {
  //       select: (data) => {
  //         console.log(data.info)
  //         return data.info;
  //       },
  //       refetchOnWindowFocus: false,
  //     }
  //   );

  if (variationQuery.isLoading || stockQuery.isLoading) return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="card bg-light">
          <div className="card-header">
            <i>{variationQuery.data[0].sku}</i> New Variation
          </div>
          <div className="card-body">
            {/* <a href={`/new-variation/${product_id}`} className="btn btn-primary btn-sm">
                  New variation
                </a> */}
            {/* </div> */}
            {/* </div> */}
          </div>
        </div>

        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">
                  Branch Stock <i>(Total: {variationQuery.data[0].qty})</i>
                </h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/products">Products</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a
                      href={`/variations/${variationQuery.data[0].product_id}`}
                    >
                      {variationQuery.data[0].sku}
                    </a>
                  </li>
                  <li className="breadcrumb-item active">
                    <i>
                      {variationQuery.data[0].color} -{" "}
                      {variationQuery.data[0].size}
                    </i>{" "}
                    stock
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Branch Info</h3>
                  </div>

                  <Formik
                    initialValues={ stockQuery.data }
                    onSubmit={(values, { setSubmitting }) => {
                      setTimeout(() => {
                        setSubmitting(false);
                        updateBranchStock(values, variation_id)
                      }, 500);
                      //   createBranch(values);
                    }}
                    // validationSchema={Yup.object().shape({
                    //   name_en: Yup.string().required(),
                    //   name_ar: Yup.string().required(),
                    // })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <Form>
                          <FieldArray
                            name="branchStock"
                            render={(arrayHelpers) => (
                              <div className="card-body">
                                {
                                // values.branchStock &&
                                // values.branchStock.length ? (
                                //   values.branchStock.map((stock, index) => (
                                    stockQuery.data && stockQuery.data.length ? (
                                        stockQuery.data.map((stock, index) => (
                                    <div className="form-group" key={index}>
                                      <div className="row">
                                        <div className="col-lg-8">
                                          <label>
                                            {stock.branch_name_en}
                                          </label>
                                        </div>

                                        <div className="col-lg-4">
                                          <input
                                            type="text"
                                            placeholder="Qty"
                                            defaultValue={
                                                stock.qty
                                            }
                                            name={`${index}.qty`}
                                            className="form-control"
                                            onChange={(handleChange)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <></>
                                )}

                                <div>
                                  <button type="submit" disabled={isSubmitting} className="btn btn-primary">Save changes</button>
                                </div>
                              </div>
                            )}
                          />
                        </Form>
                        // <form onSubmit={handleSubmit}>
                        //   <div className="card-body">
                        //     <div className="form-group">
                        //       <label>Name (EN)</label>
                        //       <input
                        //         type="text"
                        //         name="name_en"
                        //         onChange={handleChange}
                        //         onBlur={handleBlur}
                        //         value={values.name_en}
                        //         className="form-control"
                        //         placeholder="Branch name (English)"
                        //       />
                        //       {errors.name_en && touched.name_en && (
                        //         <span className="text-danger text-sm">
                        //           {errors.name_en}
                        //         </span>
                        //       )}
                        //     </div>

                        //     <div className="form-group">
                        //       <label>Name (AR)</label>
                        //       <input
                        //         type="text"
                        //         name="name_ar"
                        //         onChange={handleChange}
                        //         onBlur={handleBlur}
                        //         value={values.name_ar}
                        //         className="form-control"
                        //         placeholder="Branch name (Arabic)"
                        //       />
                        //       {errors.name_en && touched.name_en && (
                        //         <span className="text-danger text-sm">
                        //           {errors.name_en}
                        //         </span>
                        //       )}
                        //     </div>
                        //   </div>
                        //   <div className="card-footer">
                        //     <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                        //       Add Branch
                        //     </button>
                        //   </div>
                        // </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VariationStock;
