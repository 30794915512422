import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQuery } from "react-query";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import {
  fetchSuppliers,
  updateSupplier,
  fetchDepartments,
  saveDepartment,
  updateSupplierDepartments,
} from "../../services";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';

const EditSupplier = () => {
  const { supplier_id } = useParams();

  const { data: supplier, isLoading } = useQuery(
    "supplier",
    () => fetchSuppliers(supplier_id),
    {
      select: (data) => {
        return data.suppliers[0];
      },
    }
  );

  const handleCreateDepartment = async (input) => {
    try {
      await saveDepartment({ name_en: input, name_ar: input }).then((newDepartment) => {
        newSupplierDepartments.push({ label: input, value: newDepartment.data.id })
        setNewSupplierDepartments(newSupplierDepartments)
        // refetch()

        /*** Refetch has to be handled because everytime the refetch is performed,
         * `newSupplierDepartments` reset to default
        **/
      })
    } catch (e) {
      console.log(e)
    } 
  }

  const fetchedSupplierId = supplier?.id,
  [newSupplierDepartments, setNewSupplierDepartments] = useState([])

  const { status, data: departments, isLoading: depsLoading, refetch } = useQuery(
    "departments",
    fetchDepartments,
    {
      select: (data) =>
        data.departments.map((s) => {
          return { label: s.name_en, value: s.id };
        }),
        refetchOnWindowFocus: false,
        enabled: !!fetchedSupplierId,
        onSuccess: data => {
          /*data.map((d) => {
            supplier.departments.map((sd) => {
              if (d.value !== sd.id) {
                filteredDepartments.push(d)
              }
            })
          })
          setFilteredDepartments(filteredDepartments)*/
          setNewSupplierDepartments(supplier.departments.map((sd) => {
            return {label: sd.name_en, value: sd.id}
          }))
        }
    }
  );

  const saveSupplierDepartments = async (data) => {
    try {
      data = data.map(d => {
        return d.value
      })

      updateSupplierDepartments({ dep_ids: data }, supplier_id);
      // window.location.reload();
    } catch (e) {
      console.log(e)
    }
  }

  const processUpdate = async (data) => {
    try {
      await updateSupplier(data, supplier_id);
      window.location.reload();
    } catch (e) {
      console.log(e);
    }
  };

  if (isLoading || status !== "success") return <>Loading...</>;

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">New Supplier</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/suppliers">Suppliers</a>
                  </li>
                  <li className="breadcrumb-item active">New supplier</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Supplier Info</h3>
                  </div>

                  <Formik
                    initialValues={{ name_en: "", name_ar: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                      processUpdate(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      name_en: Yup.string().required(),
                      name_ar: Yup.string().required(),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <label>Name (EN)</label>
                              <input
                                type="text"
                                className="form-control"
                                name="name_en"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={supplier.name_en}
                                placeholder="Supplier name (English)"
                              />
                              {errors.name_en && touched.name_en && (
                                <span className="text-danger text-sm">
                                  {errors.name_en}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <label>Name (AR)</label>
                              <input
                                type="text"
                                name="name_ar"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={supplier.name_ar}
                                className="form-control"
                                placeholder="Supplier name (Arabic)"
                              />
                              {errors.name_ar && touched.name_ar && (
                                <span className="text-danger text-sm">
                                  {errors.name_ar}
                                </span>
                              )}
                            </div>
                            {/*<div className="form-group">
                                <label>Department</label>
                                {isLoading ? (
                                  "Loading..."
                                ) : (
                                  <>
                                    <Select
                                      closeMenuOnSelect={false}
                                      isMulti
                                      name="dep_ids"
                                      defaultValue={departments.find((d) => {
                                        return d.value === props.values.id ? d.id : null;
                                      })}
                                      onChange={ (value) => {
                                        setFieldValue("dep_ids", value.map((sv) => {
                                          return sv.value
                                        }).join(","))
                                      }}
                                      onBlur={handleBlur}
                                      options={departments}
                                    />
                                    {errors.dep_ids && touched.dep_ids && (
                                      <span className="text-danger text-sm">
                                        {errors.dep_ids}
                                      </span>
                                    )}
                                  </>
                                )}
                              </div>
  
                              <div className="form-group">
                                <a className="text-primary" href="new-department">
                                  <u>New department?</u>
                                </a>
                              </div>*/}
                          </div>
                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Add supplier
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>

              <div className="offset-md-1 col-md-5">
                <div className="card card-warning">
                  <div className="card-header">
                    <h3 className="card-title">Departments</h3>
                  </div>

                  <Formik
                    initialValues={{ dep_ids: [] }}
                    onSubmit={(values, { setSubmitting }) => {
                      saveSupplierDepartments(newSupplierDepartments)
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      dep_ids: Yup.array().required(),
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            {/* {newSupplierDepartments.length ? (
                              <Stack
                                direction="row"
                                spacing={1}
                                className="mb-3"
                              >
                                {newSupplierDepartments.map((dep) => (
                                  <Chip
                                    key={dep.id}
                                    label={dep.name_en}
                                    color="warning"
                                    onDelete={() => unassignDepartment(dep.id)}
                                  />
                                ))}
                              </Stack>
                            ) : (
                              <></>
                            )} */}

                            <div className="form-group">
                              <label>Department</label>
                              {depsLoading ? (
                                "Loading..."
                              ) : (
                                <>
                                  <CreatableSelect
                                    closeMenuOnSelect
                                    // blurInputOnSelect
                                    // closeMenuOnSelect={false}
                                    onCreateOption={handleCreateDepartment}
                                    isMulti
                                    name="dep_ids"
                                    // defaultValue={departments.find((d) => {
                                    //   return d.value === props.values.id
                                    //     ? d.id
                                    //     : null;
                                    // })}
                                    value={newSupplierDepartments}
                                    // defaultValue={departments.find((d) => {
                                    //   return d.value === props.values.id ? d.id : null;
                                    // })}
                                    /*onChange={(value) => {
                                      setFieldValue(
                                        "dep_ids",
                                        value
                                          .map((sv) => {
                                            return sv.value;
                                          })
                                      );
                                    }}*/
                                    onChange={(value) => {
                                      setNewSupplierDepartments(value)
                                    }}
                                    // onBlur={handleBlur}
                                    options={departments}
                                  />
                                  {errors.dep_ids && touched.dep_ids && (
                                    <span className="text-danger text-sm">
                                      {errors.dep_ids}
                                    </span>
                                  )}
                                </>
                              )}
                            </div>

                            {/* <div className="form-group">
                              <a className="text-primary" href="new-department">
                                <u>New department?</u>
                              </a>
                            </div> */}
                          </div>
                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn btn-warning"
                              disabled={isSubmitting}
                            >
                              Update departments
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSupplier;
