import React from "react";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { Formik } from "formik";
import * as Yup from "yup";
import { saveDepartment } from "../../services";
import { useNavigate } from 'react-router-dom';

const AddDepartment = () => {
  const navigate = useNavigate()

  const createDepartment = async (data) => {
    try {
      await saveDepartment(data)
      navigate("/new-supplier")    // Go back to previous page ...
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">New Department</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a href="/suppliers">Suppliers</a>
                  </li>
                  <li className="breadcrumb-item">
                    <a href="/new-supplier">New supplier</a>
                  </li>
                  <li className="breadcrumb-item active">New department</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        <div className="content">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Department Info</h3>
                  </div>
                  <Formik
                    initialValues={{ name_en: "", name_ar: "" }}
                    onSubmit={(values, { setSubmitting }) => {
                      createDepartment(values);
                      setSubmitting(false);
                    }}
                    validationSchema={Yup.object().shape({
                      name_en: Yup.string().required(),
                      name_ar: Yup.string().required()
                    })}
                  >
                    {(props) => {
                      const {
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                      } = props;

                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="card-body">
                            <div className="form-group">
                              <label>Name (EN)</label>
                              <input
                                type="text"
                                name="name_en"
                                value={values.name_en}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                placeholder="Department name (EN)"
                              />
                              {errors.name_en && touched.name_en && (
                                <span className="text-danger text-sm">
                                  {errors.name_en}
                                </span>
                              )}
                            </div>

                            <div className="form-group">
                              <label>Name (AR)</label>
                              <input
                                type="text"
                                name="name_ar"
                                value={values.name_ar}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                placeholder="Department name (AR)"
                              />
                              {errors.name_ar && touched.name_ar && (
                                <span className="text-danger text-sm">
                                  {errors.name_ar}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="card-footer">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Save department
                            </button>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDepartment;
