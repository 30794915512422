import React, { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid";
import { useQuery } from "react-query"
import { fetchBranches, fetchSales, fetchPaymentMethods, refundOrder, fetchEmployees } from "../../services"
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from '@mui/material/Divider';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Select from "react-select"
import moment from 'moment';

import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';


// import "react-datepicker/dist/react-datepicker.css";
// import 'react-day-picker/dist/style.css';

// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css file

import {
    Box, Chip, Button
} from "@mui/material"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

// const selectionRange = {
//     startDate: new Date("01-01-2023"),
//     endDate: new Date(),
//     key: 'selection',
//   }

const Sales = () => {
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const cancelOrder = async (orderId, restock) => {
        try {
            await refundOrder(orderId, restock)
            alert("Order has been successfully cancelled")
            window.location.reload()
        } catch (e) {
            if (e.data && e.data.message) alert(e.data.message)
            console.log(e)
        }
    }

    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", 10, 11, 12]
    const [sales, setSales] = useState([]),
        [orderItems, setOrderItems] = useState([]),
        [open, setOpen] = useState(false),
        [refundDialogOpen, setRefundDialogOpen] = useState(false),
        [filters, setFilters] = useState({}),
        [totalItems, setTotalItems] = useState(0),
        [totalSales, setTotalSales] = useState(0),
        [totalCashSales, setTotalCashSales] = useState(0),
        [totalCardSales, setTotalCardSales] = useState(0)

        
    

    const columns = [
        {
            field: "invoice_no",
            headerName: "Invoice #",
            width: 90,
            // renderCell: (params) => {
            //     return <span>{params.row.amount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</span>
            // }
        },
        {
            field: "date_add",
            headerName: "Receipt Date",
            flex: 0.6,
            renderCell: (params) => {
                let date = new Date(params.row.date_add)
                return (<span>{`${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`}</span>)
            }
        },
        {
            field: "subtotal",
            headerName: "Subtotal",
            flex: 0.45,
            renderCell: (params) => {
                return <span>{params.row.subtotal.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</span>
            }
        },
        {
            field: "discounted",
            headerName: "Disc. (EGP)",
            flex: 0.45,
            renderCell: (params) => {
                return <span>{params.row.discounted.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</span>
            }
        },
        {
            field: "total",
            headerName: "Total",
            flex: 0.5,
            renderCell: (params) => {
                return <span>{params.row.total.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ج.م</span>
            }
        },
        {
            field: "discount_name_en",
            headerName: "Discount",
            flex: 0.5,
        },
        {
            field: "promo_code",
            headerName: "Promocode",
            flex: 0.5,
        },
        {
            field: "payment_method_name_en",
            headerName: "Payment",
            flex: 0.6,
            renderCell: (params) => {
                return <Chip size="small" color={`${params.row.payment_method_id == process.env.REACT_APP_CASH_PAYMENT_METHOD ? `success` : `warning`}`} label={params.row.payment_method_name_en} />
            }
        },
        {
            field: "is_refunded",
            headerName: "Status",
            flex: 0.75,
            renderCell: (params) => {
                // Check if any of the items is refunded or all refunded
                let nRefundedItems = params.row.items.filter((obj) => obj.is_refunded === 1).length
                // let color = params.row.is_refunded > 0 ? `error` : `default`,
                let color = nRefundedItems === params.row.items.length ? `error` : (nRefundedItems ? `warning` : `default`),
                // label = params.row.is_refunded > 0 ? `Refunded` : `-`
                label =  nRefundedItems === params.row.items.length ? `Fully Refunded` : (nRefundedItems ? `Partially Refunded` : `-`)
                return <Chip size="small" color={color} label={label} />
            }
        },
        // {
        //     field: "is_restocked",
        //     headerName: "Re-Stock",
        //     flex: 0.5,
        //     renderCell: (params) => {
        //         let color = params.row.is_restocked > 0 ? `error` : `default`,
        //         label = params.row.is_restocked > 0 ? `Re-Stocked` : `-`
        //         return <Chip size="small" color={color} label={label} />
        //     }
        // },
        {
            field: "employee_name",
            headerName: "Employee",
            flex: 0.5,
        },
        /*{
            field: "branch_name_en",
            headerName: "Branch",
            flex: 1,
        },*/
        {
            field: "actions",
            headerName: "Actions",
            flex: 0.5,
            renderCell: (params) => {
                return (
                    <div className="d-flex flex-row">
                        <button
                            className="btn btn-sm btn-success mr-2"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="View items"
                            onClick={() => {
                                handleClickOpen()
                                setOrderItems(params.row)
                            }}
                        >
                            <i className="fa fa-list" />
                        </button>

                        {!params.row.is_refunded && (
                        <button
                            className="btn btn-sm btn-danger"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Refund"
                            onClick={() => {
                                setRefundDialogOpen(true)
                                setOrderItems(params.row)
                            }}
                        >
                            <i className="fa fa-ban" />
                        </button>)}
                    </div>
                )
            }
        }
    ]

    const { data, isLoading, refetch } = useQuery("sales", () => fetchSales(filters), {
        onSuccess: (data) => {
            let totalSales = 0,
            totalItems = 0,
            cashSales = 0,
            cardSales = 0,
            refundedItemsCount = 0

            data.sales.map((s) => {
                let refundedItems = s.items.filter((obj) => obj.is_refunded === 1),
                restockedNotRefunded = s.items.filter((obj) => obj.is_restocked === 1 && obj.is_refunded === 0)
                refundedItemsCount += refundedItems.length
                // let totalRefunded = refundedItems.length ? refundedItems.reduce((acc, a) => {return acc + a.price}, 0) : 0

                // totalSales += s.total - totalRefunded
                totalSales += s.total
                // totalItems += s.items.length - refundedItems.length - restockedNotRefunded.length
                // totalItems += s.items.length
                totalItems += s.items.filter((obj) => obj.is_refunded === 0).length
                // totalSales += s.is_refunded ? 0 : s.total
                // totalItems += s.is_refunded ? 0 : s.items.length
                
                // if (s.payment_method_id == process.env.REACT_APP_CASH_PAYMENT_METHOD) cashSales += s.is_refunded ? 0 : s.total
                // if (s.payment_method_id == process.env.REACT_APP_CASH_PAYMENT_METHOD) cashSales += s.total - totalRefunded
                if (s.payment_method_id == process.env.REACT_APP_CASH_PAYMENT_METHOD) cashSales += s.total
                // if (s.payment_method_id == process.env.REACT_APP_CARD_PAYMENT_METHOD) cardSales += s.total - totalRefunded
                if (s.payment_method_id == process.env.REACT_APP_CARD_PAYMENT_METHOD) cardSales += s.total
                // if (s.payment_method_id == process.env.REACT_APP_CARD_PAYMENT_METHOD) cardSales += s.is_refunded ? 0 : s.total
            })
            // totalItems -= refundedItemsCount
            setTotalSales(totalSales)
            setTotalItems(totalItems)
            setTotalCashSales(cashSales)
            setTotalCardSales(cardSales)
            setSales(data.sales)
        },
        refetchOnWindowFocus: false
    })

    const { data: employees, isLoading: isEmployeesLoading } = useQuery("employees", () => fetchEmployees(), {
        select: (data) => data.employees.map((e) => {
            return { label: `${e.firstname} ${e.lastname}`, value: e.id }
        }),
        refetchOnWindowFocus: false
    })

    const { data: branches, isLoading: isBranchesLoading } = useQuery("branches", () => fetchBranches(), {
        select: (data) => data.branches.map((b) => {
            return { label: b.name_en, value: b.id }
        })
    })

    const { data: paymentMethods, isLoading: isPaymentMethodsLoading } = useQuery("payment_methods", fetchPaymentMethods, {
        select: (data) => data.paymentMethods.map((p) => {
            return { label: p.name_en, value: p.id }
        })
    })

    const handleDateRange = (ranges) => {
        if (ranges) {
            let startDate = ranges.length ? ranges[0] : null,
            endDate = ranges.length ? ranges[1] : null``

            startDate = moment(startDate).format("yyyy-MM-DD")
            endDate = moment(endDate).format("yyyy-MM-DD")
            
            setFilters({...filters, startDate, endDate })
        } else {
            removeFilters(["startDate", "endDate"])
        }
    }

    const removeFilters = (keys) => {
        let filtersClone = {...filters}

        keys.map((key) => {
            delete filtersClone[key]
        })
        
        setFilters(filtersClone)
    }

    useEffect(() => {
        if (filters) {
            refetch()
        }
    }, [filters])

    if (isLoading || isBranchesLoading || isPaymentMethodsLoading || isEmployeesLoading) return (<>Loading...</>)

    return (
        <div className="wrapper">
            <Header />
            <Menu />

            <BootstrapDialog
                onClose={() => setRefundDialogOpen(false)}
                aria-labelledby="customized-dialog-title"
                open={refundDialogOpen}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Cancel & Refund invoice#: {orderItems.invoice_no}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => setRefundDialogOpen(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers>
                    Are you sure you want to refund & cancel this order items ?
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => cancelOrder(orderItems.id, true)}
                    >
                        Yes, Re-stock
                    </Button>
                    <Button
                        color="error"
                        onClick={() => cancelOrder(orderItems.id, false)}
                    >No, Just Cancel</Button>
                </DialogActions>
            </BootstrapDialog>

            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                //fullScreen={true}
                fullWidth={true}
                maxWidth={"md"}
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    {orderItems ? `Order items for Invoice#: ${orderItems.invoice_no}` : ``}
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers>
                    <table style={{ width: "100%" }}>
                        <thead>
                            <tr style={{ backgroundColor: "#f5f5f5" }}>
                                <th>SKU</th>
                                <th>Category</th>
                                <th>Barcode</th>
                                <th>Color</th>
                                <th>Size</th>
                                <th>Status</th>
                                <th>Original Price</th>
                                <th>Discounted Price</th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                orderItems && orderItems.items && orderItems.items.map((item) => {
                                    return (<tr style={{ height: 50 }}>
                                        <td>{item.sku}</td>
                                        <td>{item.department_name_en} {item.category_name_en}</td>
                                        <td>{item.barcode}</td>
                                        <td>{item.color}</td>
                                        <td>{item.size}</td>
                                        <td>{item.is_refunded ? <div className="badge badge-danger">Returned</div> : <span>-</span>}</td>
                                        <td>EGP {item.new_price}</td>
                                        <td>EGP {item.price}</td>
                                    </tr>)
                                })
                            }
                        </tbody>

                        <tfoot style={{ backgroundColor: "#f5f5f5" }}>
                            <tr>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th>&nbsp;</th>
                                <th style={{ textAlign: "left" }}>Total: EGP {orderItems && orderItems.total}</th>
                            </tr>
                        </tfoot>
                    </table>
                </DialogContent>
            </BootstrapDialog>
            <div className="content-wrapper">
                <div className="content-header">
                    {/* <div className="container"> */}
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Sales</h1>
                        </div>
                    </div>
                    {/* </div> */}
                </div>

                <div className="content">
                    {/* <div className="container"> */}
                    <Box sx={{ height: "70vh", width: "100%" }}>
                        <div className="card bg-dark mb-4">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-2">
                                        <div className="description-block border-right">
                                            {/* <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 17%</span> */}
                                            <h4 className="description-header">EGP {totalSales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                                            <span className="description-text">TOTAL REVENUE</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-2">
                                        <div className="description-block border-right">
                                            {/* <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 17%</span> */}
                                            <h4 className="description-header">EGP {totalCashSales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                                            <span className="description-text">CASH PAYMENTS</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-2">
                                        <div className="description-block border-right">
                                            {/* <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 17%</span> */}
                                            <h4 className="description-header">EGP {totalCardSales.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                                            <span className="description-text">CARD PAYMENTS</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-2">
                                        <div className="description-block border-right">
                                            {/* <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 17%</span> */}
                                            <h4 className="description-header">{totalItems.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                                            <span className="description-text">ITEMS SOLD</span>
                                        </div>
                                    </div>

                                    <div className="col-lg-2">
                                        <div className="description-block">
                                            {/* <span class="description-percentage text-success"><i class="fas fa-caret-up"></i> 17%</span> */}
                                            <h4 className="description-header">EGP {(totalSales / totalItems).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h4>
                                            <span className="description-text">Avg. item price</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-lg-2">
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Filter by Invoice #"
                                    defaultValue={filters?.invoice_no}
                                    onChange={({ target }) => {
                                        setFilters({ ...filters, invoice_no: target.value })
                                    }}
                                />
                            </div>

                            <div className="col-lg-2">
                                <Select
                                    options={branches}
                                    isClearable
                                    placeholder="Filter by branch"
                                    defaultValue={filters?.branch_id}
                                    onChange={(value, triggeredAction) => {
                                        value && value.value && setFilters({ ...filters, branch_id: value.value })

                                        if (triggeredAction.action === "clear") {
                                            removeFilters(["branch_id"])
                                        }
                                    }}
                                />
                            </div>

                            <div className="col-lg-2">
                                <Select
                                    options={employees}
                                    isClearable
                                    placeholder="Filter Employee"
                                    defaultValue={filters?.employee_id}
                                    onChange={(value, triggeredAction) => {
                                        value && value.value && setFilters({ ...filters, employee_id: value.value })

                                        if (triggeredAction.action === "clear") {
                                            removeFilters(["employee_id"])
                                        }
                                    }}
                                />
                            </div>

                            <div className="col-lg-3">
                                 <DateRangePicker onChange={handleDateRange} value={[filters?.startDate, filters?.endDate]} />
                            </div>

                            <div className="col-lg-3">
                                <Select
                                    options={paymentMethods}
                                    isClearable
                                    placeholder="Filter payment method"
                                    defaultValue={filters?.payment_method_id}
                                    onChange={(value, triggeredAction) => {
                                        value && value.value && setFilters({ ...filters, payment_method_id: value.value })

                                        if (triggeredAction.action === "clear") {
                                            removeFilters(["payment_method_id"])
                                        }
                                    }}
                                />
                            </div>
                        </div>

                        <DataGrid
                            rows={sales}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 50,
                                    },
                                },
                            }}
                            pageSizeOptions={[50]}
                            disableRowSelectionOnClick
                        />
                    </Box>
                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default Sales