import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQuery } from "react-query";
import { fetchBranches } from "../../services";

const Branches = () => {
  const { data: branches, isLoading } = useQuery(
    "branches",
    () => fetchBranches(),
    {
      select: (data) => {
        return data.branches;
      },
    }
  );

  const columns: GridColDef[] = [
    {
      field: "name_en",
      headerName: "Name (EN)",
      flex: 1,
    },
    {
      field: "name_ar",
      headerName: "Name (AR)",
      flex: 1,
    },
    {
      field: "date_add",
      headerName: "Date Added",
      flex: 0.5,
    },
    {
      field: "modified",
      headerName: "Last Modified",
      flex: 0.5,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      renderCell: (params) => {
        return (
          <div className="d-flex flex-row justify-content-space-between align-items-center">
            <a
              className="btn btn-sm btn-default mr-2"
              href={`/edit-branch/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Edit"
            >
              <i className="fa fa-edit" />
            </a>

            <button
              className={`mr-2 btn btn-sm btn-${
                params.row.active ? "danger" : "success"
              }`}
              data-toggle="tooltip"
              data-placement="top"
              title={`${
                params.row.active ? `Deactivate` : `Activate`
              }`}
            >
              <i
                className={`fa fa-${
                  params.row.active ? "times" : "check"
                }`}
              />
            </button>

            <a
              className="btn btn-sm btn-primary"
              href={`/branch-expenses/${params.row.id}`}
              data-toggle="tooltip"
              data-placement="top"
              title="Expenses"
            >
              <i className="fa fa-money-bill" />
            </a>
          </div>
        );
      },
    },
  ];

  if (isLoading) return <>Loading...</>;
  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Branches</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                  {/* <li className="breadcrumb-item active">Dashboard</li> */}
                </ol>
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
            <div className="row mb-4">
              <div className="col-lg-3">
                <a href="/new-branch" className="btn btn-primary btn-sm">
                  New branch
                </a>
              </div>
            </div>

            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={branches}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                }}
                pageSizeOptions={[5]}
                disableRowSelectionOnClick
              />
            </Box>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Branches;
