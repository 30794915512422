import React, { useState, useEffect } from "react";
import { Formik } from "formik"
import * as Yup from "yup"
import Cookies from "js-cookie"
import { useAuth } from "../utils/Auth"
import { login } from "../services"
import { v4 as uuidv4 } from 'uuid'

const Login = () => {
  const { setAuth } = useAuth()

  const processLogin = async (data) => {
    try {
      let res = await login({...data, uuid: uuidv4()})
        /*if (![3, 4, 5, 6, 8].includes(res.role)) {
            window.location.href = `${res.redirect_url}login?token=${res.accessToken}`
            return
        }*/
        Cookies.set("admintoken", res.accessToken)
        setAuth(true)
        window.location.href = res.redirect_url
    } catch (e) {
        alert(e.message)
    }
  }

  useEffect(() => {
    document.body.className = "hold-transition login-page";
  }, []);

  return (
    <div className="login-box d-flex flex-column justify-content-center" style={{height: "80vh"}}>
      <div className="login-logo">
        <a href="/">
          {/* <b>Admin</b>LTE */}
          <img alt="MoudyLeather" src={`${process.env.PUBLIC_URL}/images/logo-dark.png`} height="250" />
        </a>
      </div>
      <div className="card">
        <div className="card-body login-card-body">
          <p className="login-box-msg">Sign in to start your session</p>
          <Formik
            initialValues={{ username: "", password: ""}}
            onSubmit={(values, { setSubmitting }) => {
              processLogin(values)
              setSubmitting(false)
            }}
            validationSchema={
              Yup.object().shape({
                username: Yup.string().required(),
                password: Yup.string().required().min(8, "Password should be at least of 8 characters")
              })
            }
          >
            {
            props => {
                const {
                    values,
                    touched,
                    errors,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                } = props

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        name="username"
                        className="form-control"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Username"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-user" />
                        </div>
                      </div>
                    </div>
                    {errors.username && touched.username && (
                        <span className="text-danger text-sm">{errors.username}</span>
                    )}
                    <div className="input-group mb-3">
                      <input
                        type="password"
                        name="password"
                        className="form-control"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Password"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-lock" />
                        </div>
                      </div>
                    </div>
                    {errors.password && touched.password && (
                        <span className="text-danger text-sm">{errors.password}</span>
                    )}
                    <div className="row">
                      <div className="col-8">
                        <div className="icheck-primary">
                          <input type="checkbox" id="remember" />
                          <label htmlFor="remember">&nbsp;Remember Me</label>
                        </div>
                      </div>
                      <div className="col-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={isSubmitting}

                        >
                          Sign In
                        </button>
                      </div>
                    </div>
                  </form>
                )
              }
            }
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Login;
