import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const login = async (data) => {
    return request({
        url: `${BaseUrl}v1/login`,
        method: "POST",
        data
    })
}

export const fetchUser = async () => {
    return request({
        url: `${BaseUrl}v1/me`
    }, true)
}

export const fetchCustomers = async (filters) => {
    let queryStr = `?`
    Object.entries(filters).map(([key, value]) => {
        queryStr += `${key}=${value}&`
    })
    queryStr = queryStr.substring(0, queryStr.length - 1)
    
    return request({
        url: `${BaseUrl}v1/customers${queryStr}`
    }, true)
}