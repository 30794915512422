import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const fetchBranches = async (id=null) => {
    return request({
        url: `${BaseUrl}v1/branches${id ? `?id=${id}` : ``}`,
        method: "GET"
    }, true)
}

export const saveBranch = async (data) => {
    return request({
        url: `${BaseUrl}v1/branch`,
        method: "POST",
        data
    }, true)
}

export const fetchSales = async (filters) => {
    let queryStr = `?`
    Object.entries(filters).map(([key, value]) => {
        queryStr += `${key}=${value}&`
    })
    queryStr = queryStr.substring(0, queryStr.length - 1)
    return request({
        url: `${BaseUrl}v1/sales${queryStr}`,
    }, true)
}

export const fetchExpenses = (filters) => {
    let queryStr = `?`
    Object.entries(filters).map(([key, value]) => {
        queryStr += `${key}=${value}&`
    })
    queryStr = queryStr.substring(0, queryStr.length - 1)
    
    return request({
        url: `${BaseUrl}v1/expenses${queryStr}`
    }, true)
}

export const fetchExpenseModes = async () => {
    return request({
        url: `${BaseUrl}v1/fetch-expense-modes`
    }, true)
}