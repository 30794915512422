import React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQuery } from "react-query";
import { Formik } from "formik";
import { fetchEmployees } from "../../services";

const columns: GridColDef[] = [
  {
    field: "firstname",
    headerName: "First name",
    width: 150,
  },
  {
    field: "lastname",
    headerName: "Last name",
    width: 150,
  },
  {
    field: "branch_name_en",
    headerName: "Branch",
    flex: 0.5,
  },
  {
    field: "username",
    headerName: "Username",
    flex: 0.5
  },
];

const Employees = () => {
  const { data: employees, isLoading } = useQuery(
    "employees",
    () => fetchEmployees(),
    {
      select: (data) => {
        return data.employees;
      },
    }
  );

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Employees</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                </ol>
              </div>
            </div>
        </div>

        <div className="content">
            <div className="row mb-4">
              <div className="col-lg-3">
                <a href="/new-employee" className="btn btn-primary btn-sm">
                  New employee
                </a>
              </div>
            </div>

            <Box sx={{ height: "75vh", width: "100%" }}>
              {isLoading ? (
                <>Loading...</>
              ) : (
                <DataGrid
                  rows={employees}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 15,
                      },
                    },
                  }}
                  pageSizeOptions={[15]}
                  disableRowSelectionOnClick
                />
              )}
            </Box>
        </div>
      </div>
    </div>
  );
};

export default Employees;
