import React, { } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import Header from "../../components/Header";
import Menu from "../../components/Menu";
import { useQuery } from "react-query";
import { fetchSuppliers } from "../../services";
import { Chip, Stack } from "@mui/material";

const Suppliers = () => {
  const { data: suppliers, isLoading } = useQuery(
    ["suppliers"],
    () => fetchSuppliers(),
    {
      select: (data) => data.suppliers,
    }
  );

  const columns: GridColDef[] = [
    { field: 'name_en', headerName: 'Name (EN)', flex: 0.5 },
    { field: 'name_ar', headerName: 'Name (AR)', flex: 0.5 },
    { 
      field: "departments",
      headerName: "Departments",
      flex: 1, 
      renderCell: (params) => {
        return (
          <Stack spacing={2}>
            {
              params.row.departments.map((d) => {
                return (
                <Chip
                  key={d.id}
                  label={d.name_en}
                  color={"info"}
                />)
              })      
            }
          </Stack>
        )
      }
    },
    { field: 'date_add', headerName: 'Date Added', flex: 1 },
    { field: 'modified', headerName: 'Last Modified', flex: 1 },
    {
        field: 'actions',
        headerName: 'Actions',
        flex: 1,
        renderCell: (params) => {
            return (
                <div className="d-flex flex-row justify-content-space-between align-items-center">
                    <a
                      className="btn btn-sm btn-default mr-2"
                      href={`/edit-supplier/${params.row.id}`}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Edit"
                    >
                        <i className="fa fa-edit" />
                    </a>

                    {/* <a 
                      className="btn btn-sm btn-warning mr-2"
                      href={`/supplier-departments/${params.row.id}`}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Departments"
                    >
                        <i className="fa fa-puzzle-piece" />
                    </a> */}

                    <a 
                      className="btn btn-sm btn-primary mr-2"
                      href={`/supplier/log/${params.row.id}`}
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Log"
                    >
                        <i className="fa fa-list" />
                    </a>

                    <button
                      className={`btn btn-sm btn-${parseInt(params.row.active) ? "danger" : "success"}`}
                      data-toggle="tooltip"
                      data-placement="top"
                      title={`${parseInt(params.row.active) ? `Deactivate` : `Activate`}`}
                    >
                        <i className={`fa fa-${parseInt(params.row.active) ? "times" : "check"}`} />
                    </button>
                </div>
            )
        }
    },
  ]

  return (
    <div className="wrapper">
      <Header />
      <Menu />
      <div className="content-wrapper">
        <div className="content-header">
          {/* <div className="container"> */}
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">Suppliers</h1>
              </div>

              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  {/* <li className="breadcrumb-item"><a href="#">Home</a></li> */}
                  {/* <li className="breadcrumb-item active">Dashboard</li> */}
                </ol>
              </div>
            </div>
          {/* </div> */}
        </div>

        <div className="content">
          {/* <div className="container"> */}
            <div className="row mb-4">
              <div className="col-lg-3">
                <a href="/new-supplier" className="btn btn-primary btn-sm">
                  New supplier
                </a>
              </div>
            </div>

            <Box sx={{ height: 400, width: "100%" }}>
              {isLoading ? (
                "Loading"
              ) : (
                <DataGrid
                  rows={suppliers}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  disableRowSelectionOnClick
                />
              )}
            </Box>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default Suppliers;
