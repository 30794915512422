import { request } from "../utils/Request"
const BaseUrl = process.env.REACT_APP_API_URL

export const fetchPaymentMethods = async () => {
    return request({
        url: `${BaseUrl}v1/payment_methods`,
        method: "GET",
    }, true)
}

export const refundOrder = async (orderId, restock) => {
    return request({
        url: `${BaseUrl}v1/cancel-order/${orderId}`,
        method: "PUT",
        data: { restock }
    }, true)
}

export const fetchMetrics = async () => {
    return request({
        url: `${BaseUrl}v1/metrics`
    }, true)
}