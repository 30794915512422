import React, { useEffect } from "react"
import Header from "../components/Header"
import Menu from "../components/Menu"
import { DataGrid } from "@mui/x-data-grid"
import { useQuery } from "react-query"
import {
    fetchMetrics
} from "../services"
import {
    Box
} from "@mui/material"

const Dashboard = () => {
    const monthlySalesColumns = [
        {
            field: "yearmonth",
            headerName: "Month",
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <span>{params.row.yearmonth.toString().substring(0, 4)}-{params.row.yearmonth.toString().substring(4, params.row.yearmonth.length)}</span>
            },
            flex: 1
        },
        {
            field: "n_sales",
            headerName: "# Sales",
            headerClassName: 'super-app-theme--header',
        },
        {
            field: "total_sales",
            headerName: "(EGP) Total Sales",
            headerClassName: 'super-app-theme--header',
            renderCell: (params) => {
                return <span>EGP {params.row.total_sales?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            },
            flex: 0.3
        },
        {
            field: "n_items",
            headerName: "# Items",
            headerClassName: 'super-app-theme--header',
            flex: 0.3,
            renderCell: (params) => {
                return <span>{params.row.n_items.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            }
        }
    ],
    categorySalesColumns = [
        {
            field: "category_name",
            headerName: "Category",
            flex: 0.5,
            renderCell: (params) => {
                return <span>{params.row.department_name_en} {params.row.category_name_en}</span>
            }
        },
        {
            field: "n_items",
            headerName: "# Sales",
            renderCell: (params) => {
                return <span>{params.row.n_items.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
            }
        }
    ]

    const { data, isLoading } = useQuery("sales", () => fetchMetrics(), {
        select: data => data,
        refetchOnWindowFocus: false
    })

    if (isLoading) return <>Loading...</>

    return (
        <div className="wrapper">
            <Header />
        <Menu />
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Dashboard</h1>
                        </div>

                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-6">
                            <h5>Monthly Sales Breakdown</h5>
                            <Box sx={{
                                maxHeight: "500px",
                                backgroundColor: "#FFF",
                                overflowY: "scroll",
                                '& .super-app-theme--header': {
                                backgroundColor: '#CFFDBC',
                                },
                                '& .super-app-theme--header *': {
                                    fontWeight: "bold"
                                }
                            }}
                            >
                                
                                <DataGrid
                                    rows={data.sales}
                                    columns={monthlySalesColumns}
                                    getRowId={(row) => row?.yearmonth}
                                    hideFooter
                                />
                            </Box>
                        </div>

                        <div className="col-lg-6">
                            <h5>Category Sales Breakdown</h5>
                            <Box sx={{ maxHeight: "400px", backgroundColor: "#FFF" }}>
                                <DataGrid
                                    rows={data.categorySales}
                                    columns={categorySalesColumns}
                                    getRowId={(row) => row?.category_id}
                                    hideFooter
                                />
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Dashboard